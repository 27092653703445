import styled from 'styled-components'

export const SlideshowContainer = styled.div`
    overflow: hidden;
    margin: 0 auto;
    width: 100%;
`

export const SlideshowSlider = styled.div<{
    length: number
    index: number
}>`
    white-space: nowrap;
    transition: ease 1000ms;
    transform: translate3d(${props => -props.index * (100 / props.length)}%, 0, 0);
`

export const Slide = styled.div<{ length: number }>`
    display: inline-block;
    width: ${props => 100 / props.length}%;
`
