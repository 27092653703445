import { FC, useEffect } from 'react'

import { useCurrentSlideIndex } from '@src/hooks/carousel'
import { AnalyticsService } from '@src/services'
import { homePageEventCategories } from '@src/services/analytics-service/categories'
import { homePageEventLabels } from '@src/services/analytics-service/labels'

interface Props {
    eventAction: string
}

const TrackSlideChange: FC<Props> = ({ eventAction }) => {
    const currentSlideIndex = useCurrentSlideIndex()
    useEffect(() => {
        AnalyticsService.trackEvent(
            eventAction,
            homePageEventCategories.TESTIMONIALS_SECTION,
            homePageEventLabels.testimonialsSection.CHANGE_CAROUSEL_SLIDE,
        )
    }, [eventAction, currentSlideIndex])

    return null
}

export default TrackSlideChange
