import styled from 'styled-components'
import { wideScreenBreakpoint } from 'react-components'

interface ComponentProps {
    showComponent?: boolean
}

export const WideScreenContainer = styled.div`
    display: none;
    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        display: ${props => props.theme.style.display.flex};
        flex-direction: column;
        padding: 8px 0;
        &:hover {
            background-color: #2298da0a;
        }
    }
`

export const MobileOrTabletOrDesktopContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex-direction: column;
    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        display: none;
    }
`

export const LinkContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    align-items: center;
    padding: 8px 6px;
`

export const LinkTextContainer = styled.div``

export const IconContainer = styled.div`
    width: 24px;
    height: 24px;
`

export const ArrowIcon = styled.div`
    padding: 8px;
    width: 100%;
    height: 100%;
`

export const CollapsibleContainer = styled.div<ComponentProps>`
    position: absolute;
    display: ${props => (props.showComponent === true ? 'flex' : 'none')};
    flex-direction: column;
    background: ${({ theme }) => theme.colors.white};
    margin-top: ${({ theme }) => theme.spacing.large};
    box-shadow: 0 6px 10px #0000000f, 0 1px 18px #0000000a, 0 3px 5px #00000014;
    border-radius: ${({ theme }) => theme.typography.border.radius.small};
`
