import store from 'store2'

import { isServer } from '@common/utils/platform-utils'

class SessionStorageManagerService {
    public static set(key: string, value: any): void {
        if (!isServer) {
            store.session.set(key, value)
        }
    }

    public static get(key: string): any | undefined {
        if (!isServer) {
            return store.session.get(key)
        }
    }

    public static delete(key: string): void {
        if (!isServer) {
            return store.session.remove(key)
        }
    }
}

export default SessionStorageManagerService
