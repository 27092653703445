import React, { FC, memo } from 'react'

import {
    FairnessPrincipleModalTransport,
    MoneyBackGuaranteeModalTransport,
} from '@common/transports/home-page-transport'
import { SectionContainer } from '@src/components/home-page-v3/styles'
import { Container, Section, Subtitle, Title, TitleContainer } from './styles'
import Benefits from './benefits'
import { useTranslation } from '@src/hooks/locale'

interface Props {
    fairnessPrincipleModal: FairnessPrincipleModalTransport
    moneyBackGuaranteeModal: MoneyBackGuaranteeModalTransport
}

const BenefitsSection: FC<Props> = ({ fairnessPrincipleModal, moneyBackGuaranteeModal }) => {
    const { t } = useTranslation()

    return (
        <SectionContainer id="benefits">
            <Container>
                <Section>
                    <TitleContainer>
                        <Title>{t('benefitsTitle')}</Title>
                        <Subtitle>{t('benefitsSubtitle')}</Subtitle>
                    </TitleContainer>
                    <Benefits
                        fairnessPrincipleModal={fairnessPrincipleModal}
                        moneyBackGuaranteeModal={moneyBackGuaranteeModal}
                    />
                </Section>
            </Container>
        </SectionContainer>
    )
}

export default memo(BenefitsSection)
