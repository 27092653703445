import React from 'react'
import { RegistrationFormWrapper } from './ho-registration-form-styles'
import RegistrationFormSection from './registration-form-section'
import { NotificationMessageState } from '@src/redux/notification-channel'
import { FormSectionTransport } from '@common/transports/ho-registration-form-transports'
import MultiDeviceImageTransport from '@common/transports/common/multi-device-image-transport'

interface Props {
    registrationForm: FormSectionTransport
    disposeNotification: (message: NotificationMessageState) => void
    backgroundImage: MultiDeviceImageTransport
    isAdvertisementGlowing: boolean
}

const HORegistrationFormWrapper: React.FC<Props> = ({
    registrationForm,
    disposeNotification,
    backgroundImage,
    isAdvertisementGlowing,
}) => {
    return (
        <RegistrationFormWrapper>
            <RegistrationFormSection
                formSection={registrationForm}
                disposeNotification={disposeNotification}
                backgroundImage={backgroundImage}
                isAdvertisementGlowing={isAdvertisementGlowing}
            />
        </RegistrationFormWrapper>
    )
}

export default HORegistrationFormWrapper
