import styled from 'styled-components'
import { desktopBreakpoint, tabletBreakpoint, ResponsiveImage, wideScreenBreakpoint } from 'react-components'

export const Container = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing.xxLarge};
    &:last-child {
        margin-bottom: unset;
    }
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        display: ${props => props.theme.style.display.flex};
        flex-direction: row;
        justify-content: space-between;
        text-align: left;
        margin-bottom: 3.125rem;
        width: 100%;
    }
`

export const MLWBenefitsContainer = styled.div`
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        width: 80%;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        width: 60%;
    }
`

export const MLWImageContainer = styled.div`
    display: none;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        display: block;
        width: 50%;
    }
`

export const InnerContainer = styled.div`
    margin-top: 16px;
    display: ${props => props.theme.style.display.flex};
    flex-direction: column;
    width: 100%;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        flex-direction: row;
        margin-top: 24px;
    }

    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        flex-direction: row;
        margin-top: 32px;
    }
`
