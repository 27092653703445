import React, { FC, useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { Card } from 'react-components'

import { HowItWorksStepTransport } from '@common/transports/home-page-transport'
import { CardContentContainer, Description, Title } from './how-it-works-step-card-styles'

interface Props {
    step: HowItWorksStepTransport
}

const HowItWorksStepCard: FC<Props> = ({ step: { title, description } }) => {
    const theme = useContext(ThemeContext)

    return (
        <Card>
            <CardContentContainer>
                <Title>{title}</Title>
                <Description>{description}</Description>
            </CardContentContainer>
        </Card>
    )
}

export default HowItWorksStepCard
