import styled from 'styled-components'
import { desktopBreakpoint, tabletBreakpoint, wideScreenBreakpoint } from 'react-components'

import { H5, P } from '@src/components/common/styles'

export const CardContentContainer = styled.div`
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        max-width: 210px;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        max-width: 310px;
    }
    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        max-width: 320px;
    }
`

export const Title = styled(H5).attrs({ as: 'h2' })`
    font: 700 ${({ theme }) => theme.typography.font.size.large} / 26px
        ${({ theme }) => theme.typography.font.family.inter};
    color: ${({ theme }) => theme.colors.tfwBlack99};
`

export const Description = styled(P)`
    font: 400 ${({ theme }) => theme.typography.font.size.small} / 22px
        ${({ theme }) => theme.typography.font.family.inter};
    color: ${({ theme }) => theme.colors.tfwBlack40};
    margin-top: ${({ theme }) => theme.spacing.xxxSmall};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-top: 4px;
    }
`
