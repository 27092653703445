import React, { useContext, memo } from 'react'
import ListingCriteriaItem from './listing-criteria-item'
import {
    ModalContainer,
    CloseButton,
    ModalTitle,
    BulletPointContainer,
    BulletPointBody,
    Description,
    DreamIndexCriteria,
    DeactivateParagraph,
    StickyTopBar,
} from './additional-policies-section-modal-styles'
import CloseIcon from '../../../assets/images/modal-cross-icon.svg'
import { ScreenContext } from 'react-components'
import { MoneyBackGuaranteeModalTransport } from '@common/transports/home-page-transport'

type Props = {
    onClick: () => void
    modalSection: MoneyBackGuaranteeModalTransport
}

const MoneyBackGuaranteeModal: React.FC<Props> = ({
    onClick,
    modalSection: { title, description, deactivationDescription, listingCriteria },
}) => {
    const { isMobile } = useContext(ScreenContext)
    return (
        <ModalContainer>
            {isMobile ? (
                <StickyTopBar>
                    <CloseButton onClick={onClick}>
                        <CloseIcon />
                    </CloseButton>
                </StickyTopBar>
            ) : (
                <CloseButton onClick={onClick}>
                    <CloseIcon />
                </CloseButton>
            )}
            <ModalTitle>{title}</ModalTitle>
            <Description inputText={description} escapeHtml />
            <DreamIndexCriteria inputText={listingCriteria.description} escapeHtml />
            <BulletPointContainer>
                {listingCriteria.criteria.map((criteria, index) => (
                    <ListingCriteriaItem index={index + 1} key={index + 1}>
                        <BulletPointBody inputText={criteria.description} escapeHtml />
                    </ListingCriteriaItem>
                ))}
            </BulletPointContainer>
            <DeactivateParagraph inputText={deactivationDescription} escapeHtml />
        </ModalContainer>
    )
}

export default memo(MoneyBackGuaranteeModal)
