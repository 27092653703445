import React, { useEffect, useState, useCallback } from 'react'

interface StickyButtonsProps {
    targetRef: React.RefObject<HTMLDivElement>
}

import {
    Container,
    InnerContainer,
    ButtonContainer,
    ButtonContainerText,
    CallUsInnerContainer,
    CallButtonContainer,
    PhoneImage,
    IconContainer,
    CallUsButton,
    RegisterButton,
} from './styles'

import { AnalyticsService } from '@src/services'
import { homePageEventCategories } from '@src/services/analytics-service/categories'
import { homePageEventLabels } from '@src/services/analytics-service/labels'
import { useTranslation } from '@src/hooks/locale'

const StickyButtons: React.FC<StickyButtonsProps> = ({ targetRef }) => {
    const [isVisible, setIsVisible] = useState(true)

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setIsVisible(false)
                    } else {
                        setIsVisible(true)
                    }
                })
            },
            { threshold: 0.5 },
        )

        if (targetRef.current) {
            observer.observe(targetRef.current)
        }

        return () => {
            if (targetRef.current) {
                observer.unobserve(targetRef.current)
            }
        }
    }, [targetRef])

    const _handleButtonClick = useCallback(data => {
        switch (data) {
            case 'Call Us Button':
                AnalyticsService.trackClickEvent(
                    homePageEventCategories.CANARY_HO,
                    homePageEventLabels.StickyButtons.CALL_US,
                )
                break
            case 'Register Now Button':
                AnalyticsService.trackClickEvent(
                    homePageEventCategories.CANARY_HO,
                    homePageEventLabels.StickyButtons.REGISTER_NOW,
                )
                break
        }
    }, [])

    const { t } = useTranslation()

    return (
        <Container visibility={isVisible}>
            <InnerContainer>
                <ButtonContainer>
                    <CallUsButton
                        href="tel:+49 421 - 146 29 600"
                        onClick={() => {
                            _handleButtonClick('Call Us Button')
                        }}
                    >
                        <CallButtonContainer>
                            <CallUsInnerContainer>
                                <IconContainer>
                                    <PhoneImage />
                                </IconContainer>
                                <ButtonContainerText>{t('callUs')}</ButtonContainerText>
                            </CallUsInnerContainer>
                        </CallButtonContainer>
                    </CallUsButton>
                </ButtonContainer>
                <ButtonContainer>
                    <RegisterButton
                        href="#registration-form"
                        onClick={() => {
                            _handleButtonClick('Register Now Button')
                        }}
                    >
                        <ButtonContainerText>{t('registerNow')}</ButtonContainerText>
                    </RegisterButton>
                </ButtonContainer>
            </InnerContainer>
        </Container>
    )
}

export default StickyButtons
