import React, { FC, useCallback, useMemo } from 'react'

import { StyledFAQGroup } from './faq-group-styles'
import { FAQGroupTransport } from '@common/transports/home-page-transport'
import { SerializableObject } from 'react-components'

interface Props {
    isSelected: boolean
    currentGroup: number
    faqGroup: FAQGroupTransport
    onClick: (currentGroup: number) => void
}

const _getRichSnippetHtml = (richSnippet?: SerializableObject) =>
    richSnippet
        ? {
              __html: JSON.stringify(richSnippet),
          }
        : null

const FAQGroup: FC<Props> = ({ isSelected, currentGroup, faqGroup, onClick }) => {
    const _handleChange = useCallback(() => {
        onClick(currentGroup)
    }, [currentGroup, onClick])

    const _richSnippetHtml = useMemo(() => _getRichSnippetHtml(faqGroup.richSnippet), [faqGroup.richSnippet])

    return (
        <StyledFAQGroup isSelected={isSelected} onClick={_handleChange}>
            {faqGroup.label}
            {!!_richSnippetHtml && <script type="application/ld+json" dangerouslySetInnerHTML={_richSnippetHtml} />}
        </StyledFAQGroup>
    )
}

export default FAQGroup
