import styled, { StyledComponent } from 'styled-components'

import { H5 } from '@src/components/common/styles'

interface NumberContainerProps {
    textColor?: string
}

export const NumberContainer = styled(H5 as StyledComponent<'p', any>).attrs({ as: 'p' })<NumberContainerProps>`
    color: ${props => props.textColor || props.theme.colors.tfwBlack87};
`
