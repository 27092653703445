import styled, { AnyStyledComponent } from 'styled-components'
import { CarouselProvider, Slider, ButtonBack, ButtonNext, Dot } from 'pure-react-carousel'
import { wideScreenBreakpoint, tabletBreakpoint, desktopBreakpoint } from 'react-components'

import Arrow from '../../../assets/images/right-black-arrow.svg'
import { H3, P } from '@src/components/common/styles'

export const SectionContainer = styled.div`
    scroll-margin-top: 10px;
`

export const Container = styled.div`
    padding: 40px 0 50px;
    border-bottom: ${({ theme }) => `${theme.typography.border.width.xxxThin} solid ${theme.colors.tfwWhite32}`};

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: 3.125rem 0;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        border-bottom: unset;
        padding: 80px 0;
    }
    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        padding: 114px 110px 0 110px;
    }
`

export const TitleContainer = styled.div`
    padding: 0 ${({ theme }) => theme.spacing.medium};
    text-align: center;
    max-width: 1200px;
    display: ${props => props.theme.style.display.flex};
    margin: auto;
    flex-direction: column;

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        padding: 0 ${({ theme }) => theme.spacing.large};
    }
`

export const Title = styled(H3).attrs({ as: 'h1' })`
    margin-bottom: 8px;
    font: 700 25px/30px ${({ theme }) => theme.typography.font.family.inter};
    color: ${({ theme }) => theme.colors.tfwBlack99};

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: 32px;
        line-height: 40px;
        padding-top: 16px;
    }
`

export const Subtitle = styled(P)`
    font: 400 ${({ theme }) => theme.typography.font.size.small} / 22px
        ${({ theme }) => theme.typography.font.family.inter};
    color: ${({ theme }) => theme.colors.tfwBlack40};
    margin-bottom: 8px;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: 16px;
        line-height: 24px;
    }
`
export const StyledCarouselProvider = styled(CarouselProvider)`
    margin-top: 16px;
    li {
        list-style: none;
        margin: 0 ${({ theme }) => theme.spacing.xSmall};
        background-color: #f5f5f5;
        border-radius: 1rem;
    }

    /* This is done to have a center mode. */
    li:first-child {
        margin-left: -${({ theme }) => theme.spacing.xSmall};
    }
    li:last-child {
        margin-right: -${({ theme }) => theme.spacing.xSmall};
    }

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        li {
            margin: 0 ${({ theme }) => theme.spacing.small};
        }

        /* This is done to have a center mode. */
        li:first-child {
            margin-left: 0;
        }
        li:last-child {
            margin-right: 0;
        }
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        padding: 0 ${({ theme }) => theme.spacing.small};
        li,
        li:first-child,
        li:last-child {
            margin: 0 ${({ theme }) => theme.spacing.small};
        }
    }

    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        padding: 0 1rem;
        li,
        li:first-child,
        li:last-child {
            margin: 0 ${({ theme }) => theme.spacing.medium};
        }
    }
`

export const SliderContainer = styled.div`
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        /* Add margin from both sides to the actual max width. */
        max-width: calc(1200px + 2rem);
        position: relative;
        margin: 0 auto;
    }
`

// Leave the hard-coded padding values as-is as they are necessary to be able to calculate the exact effect of those
// padding values.
export const StyledSlider = styled(Slider)`
    /* This is done to have a center mode. */
    padding: 0 24px;

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        padding: 0;
    }
`

export const StyledButtonBack = styled(ButtonBack)`
    display: none;
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        display: block;
        padding: 0;
        position: absolute;
        top: 50%;
        right: 100%;
        transform: translate(80%, -50%);
        cursor: pointer;
        border: none;
        outline: none;
        width: ${({ theme }) => theme.layout.medium};
        height: ${({ theme }) => theme.layout.medium};
        border-radius: 50%;
        z-index: 1;
        box-shadow: 0 7px 13px ${({ theme }) => theme.colors.tfwBlack12};
    }
`

export const StyledButtonNext = styled(StyledButtonBack).attrs({ as: ButtonNext })`
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        right: unset;
        left: 100%;
        transform: translate(-80%, -50%);
    }
`

export const RightArrowIcon = styled(Arrow as AnyStyledComponent)`
    vertical-align: middle;
`

export const LeftArrowIcon = styled(RightArrowIcon)`
    transform: scaleX(-1);
`

export const DotContainer = styled.div`
    text-align: ${({ theme }) => theme.style.textAlign.center};
    margin-top: ${({ theme }) => theme.spacing.xLarge};
`

export const StyledDot = styled(Dot)`
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    padding: ${({ theme }) => `0 ${theme.spacing.xSmall}`};
`
