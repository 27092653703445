import React, { FC, useContext, Fragment, useState, useCallback, useEffect } from 'react'
import { AnyStyledComponent, ThemeContext } from 'styled-components'

import { useHover, useOutsideClick } from '@src/hooks/dom'
import SecondaryNavigationButtonsItem from '@src/components/home-page-v3/header-section/secondary-navigation-button-item'
import ButtonTransport from '@common/transports/common/button-transport'
import {
    CollapsibleContainer,
    WideScreenContainer,
    MobileOrTabletOrDesktopContainer,
    ArrowIcon,
    LinkContainer,
    LinkTextContainer,
    IconContainer,
} from '@src/components/home-page-v3/header-section/secondary-navigation-buttons-styles'
import DownArrowIcon from '../../../assets/images/down-arrow.svg'
import { AnalyticsService } from '@src/services'
import { homePageEventCategories } from '@src/services/analytics-service/categories'

interface Props {
    title?: string
    eventLabel?: string
    buttons: ButtonTransport[]
}

const SecondaryNavigationButtons: FC<Props> = ({ title, buttons, eventLabel }) => {
    const [isCollapsed, setIsCollapsed] = useState(false)
    const _toggleCollapsibleContainer = useCallback(() => setIsCollapsed(isCollapsed => !isCollapsed), [])
    const _checkCollapsibleContainerStatus = useCallback(() => {
        if (isCollapsed) {
            _toggleCollapsibleContainer()
        }
    }, [_toggleCollapsibleContainer, isCollapsed])

    const [_collapsibleTabletContainerRef] = useOutsideClick<HTMLDivElement>(_checkCollapsibleContainerStatus)
    const [_collapsibleDesktopContainerRef, isBeingHovered] = useHover<HTMLDivElement>(true)
    const theme = useContext(ThemeContext)

    const _trackClick = useCallback(() => {
        if (eventLabel) {
            AnalyticsService.trackClickEvent(homePageEventCategories.HEADER_SECTION, eventLabel)
        }
    }, [eventLabel])

    const _handleCollapsibleContainerClick = useCallback(() => {
        _toggleCollapsibleContainer()
        _trackClick()
    }, [_trackClick, _toggleCollapsibleContainer])

    useEffect(() => {
        if (isBeingHovered && eventLabel) {
            AnalyticsService.trackMouseoverEvent(homePageEventCategories.HEADER_SECTION, eventLabel)
        }
    }, [eventLabel, isBeingHovered])

    return (
        <Fragment>
            <WideScreenContainer ref={_collapsibleDesktopContainerRef}>
                <LinkContainer>
                    <LinkTextContainer>{title}</LinkTextContainer>
                    <IconContainer>
                        <ArrowIcon as={DownArrowIcon as AnyStyledComponent} alt="Down Arrow" />
                    </IconContainer>
                </LinkContainer>
                <CollapsibleContainer showComponent={isBeingHovered}>
                    {buttons.map(button => (
                        <SecondaryNavigationButtonsItem
                            key={button.url}
                            item={button}
                            paddingVertical={theme.spacing.medium}
                            paddingHorizontal={theme.spacing.medium}
                        />
                    ))}
                </CollapsibleContainer>
            </WideScreenContainer>
            <MobileOrTabletOrDesktopContainer ref={_collapsibleTabletContainerRef}>
                <LinkContainer onClick={_handleCollapsibleContainerClick}>
                    <LinkTextContainer>{title}</LinkTextContainer>
                    <IconContainer>
                        <ArrowIcon as={DownArrowIcon as AnyStyledComponent} alt="Down Arrow" />
                    </IconContainer>
                </LinkContainer>
                <CollapsibleContainer showComponent={isCollapsed}>
                    {buttons.map(button => (
                        <SecondaryNavigationButtonsItem
                            key={button.url}
                            item={button}
                            paddingVertical={theme.spacing.medium}
                            paddingHorizontal={theme.spacing.medium}
                        />
                    ))}
                </CollapsibleContainer>
            </MobileOrTabletOrDesktopContainer>
        </Fragment>
    )
}

export default SecondaryNavigationButtons
