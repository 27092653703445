import React, { useContext, useEffect } from 'react'
import { useSnackbar } from 'notistack'

import { ScreenContext } from 'react-components'

import { NotificationState } from '../../redux/notification-channel'

export interface NotificationProp {
    messages: NotificationState[]
    onDismiss: (id: string) => void
}

let displayedNotifications: Array<string> = []

const storeDisplayed = (id: string) => {
    displayedNotifications.push(id)
}

const removeFromDisplayed = (id: string) => {
    displayedNotifications = [...displayedNotifications.filter(key => id !== key)]
}

const Notification: React.FC<NotificationProp> = ({ messages, onDismiss }) => {
    const { isMobile } = useContext(ScreenContext)

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    useEffect(() => {
        messages.forEach(({ id, message, messageType, duration }) => {
            const options = { autoHideDuration: duration }
            if (!duration) {
                delete options.autoHideDuration
            }
            if (displayedNotifications.includes(id)) {
                return
            }
            enqueueSnackbar({
                message,
                key: id,
                variant: messageType,
                ...options,
                anchorOrigin: { vertical: !isMobile ? 'top' : 'bottom', horizontal: !isMobile ? 'right' : 'center' },
                onClick: () => {
                    removeFromDisplayed(id)
                    onDismiss(id)
                    closeSnackbar(id)
                },
                onExited: () => {
                    removeFromDisplayed(id)
                    onDismiss(id)
                    closeSnackbar(id)
                },
            })

            storeDisplayed(id)
        })
    }, [closeSnackbar, enqueueSnackbar, isMobile, messages, onDismiss])

    return null
}

export default Notification
