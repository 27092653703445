const _listingTypeMap = new Map([
    [2, 'Apartment'],
    [3, 'Farmhouse'],
    [4, 'Organic farm'],
    [5, 'Bungalow'],
    [9, 'Holiday house'],
    [12, 'Holiday apartment'],
    [14, 'Holiday room'],
    [15, 'Finca'],
    [21, 'Cottage'],
    [23, 'Pension'],
    [25, 'Villa'],
    [28, 'Trailer'],
    [30, 'House boat'],
    [31, 'B&B'],
    [32, 'Chalet'],
    [33, 'Castle'],
    [35, 'Cave dwelling'],
    [38, 'Ski lodge'],
    [39, 'Residence'],
    [40, 'Alpine Hut'],
    [41, 'Sailing ship'],
    [42, 'Guestroom'],
    [43, 'Terraced house'],
    [44, 'Holiday cottage'],
])

export const getListingTypeLabel = (value: number) => _listingTypeMap.get(value)
