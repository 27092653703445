import React, { FC, Fragment, memo } from 'react'
import 'pure-react-carousel/dist/react-carousel.es.css'

import { SliderContainer, ImageSliderLoader, ImageSliderContainer } from './platform-styles'
import Slider from '../slider'

import { items } from '../hero-section/hero-slider-items'

const PlatformContainer: FC = () => {
    const renderItems = (src: string, alt: string): React.ReactElement<HTMLElement> => {
        return (
            <ImageSliderContainer>
                <ImageSliderLoader src={src} alt={alt} width={83} height={26} />
            </ImageSliderContainer>
        )
    }

    return (
        <Fragment>
            <SliderContainer>
                <Slider
                    items={items}
                    visibleSlide={2.5}
                    delay={3000}
                    renderItems={(src: string, alt: string) => renderItems(src, alt)}
                />
            </SliderContainer>
        </Fragment>
    )
}

export default memo(PlatformContainer)
