import cloneDeep from 'lodash/cloneDeep'
import unset from 'lodash/unset'
import { isObject } from 'formik'

/**
 * Function to delete a nested property in an object.
 * Currently, deep clones the object.
 *
 * TODO: Make shallow copies along the path instead of deep-copying.
 */
export const deleteIn = (obj: any, path: string | string[]) => {
    const res = cloneDeep(obj)
    unset(res, path)
    return res
}

/**
 * Function to check whether there are any non-null and non-undefined leaf nodes present in an object.
 */
export const isLeafNodePresent = (obj: object | any[]): boolean => {
    for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const value = obj[key]
            if (isObject(value) || Array.isArray(isObject)) {
                const isPresent = isLeafNodePresent(value)
                if (isPresent) {
                    return isPresent
                }
            } else if (value !== undefined && value !== null) {
                return true
            }
        }
    }
    return false
}

/**
 * Checks if an object/array is completely empty.
 *
 * For an object to be empty, all of its leaf nodes need to match `undefined`, `null`, `''` or `{}`.
 */
export const isDeepEmpty = (item: any): boolean =>
    item === undefined ||
    item === null ||
    item === '' ||
    (Array.isArray(item) && item.every((element: any) => isDeepEmpty(element))) ||
    (typeof item === 'object' && Object.values(item).every((element: any) => isDeepEmpty(element)))

export const updateTemplate = (template: string | undefined | null, values: { [key: string]: string }) => {
    return template
        ? Object.keys(values).reduce((result, key) => result.replace(`{{${key}}}`, values[key]), template)
        : ''
}
