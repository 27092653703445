import React, { FC, memo, useCallback } from 'react'

import ButtonTransport from '@common/transports/common/button-transport'
import { StyledNavigationItem } from './navigation-item-styles'
import { AnalyticsService } from '@src/services'
import { homePageEventCategories } from '@src/services/analytics-service/categories'

interface Props {
    button: ButtonTransport
}

const NavigationItem: FC<Props> = ({ button }) => {
    const _handleClick = useCallback(() => {
        if (button.eventLabel) {
            AnalyticsService.trackClickEvent(homePageEventCategories.HEADER_SECTION, button.eventLabel)
        }
    }, [button.eventLabel])

    return (
        <StyledNavigationItem href={button.url} onClick={_handleClick}>
            {button.text}
        </StyledNavigationItem>
    )
}

export default memo(NavigationItem)
