import styled from 'styled-components'
import { tabletBreakpoint, wideScreenBreakpoint, desktopBreakpoint, SolidButton } from 'react-components'
import { H4, CustomMarkdown } from '@src/components/common/styles'

export const RegisterButton = styled(SolidButton)`
    padding: 0;
    width: ${({ theme }) => theme.layout.fill};
    background-color: #f1aa10;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        max-width: 138px;
    }
`

export const CustomerSupportWrapper = styled.div`
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 ${({ theme }) => theme.spacing.medium};

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: 0 1rem;
    }

    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        margin-top: 32px;
        padding: 0 0;
    }
`

export const CSupportContainer = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    padding: 8px 24px;
    margin: 0 0 ${({ theme }) => theme.spacing.xxLarge};
    background-color: #fff9eb;
    border-radius: 16px;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        flex-direction: ${({ theme }) => theme.style.flexDirection.row};
        margin: 0 0 40px;
        padding: ${({ theme }) => theme.spacing.large};
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        flex-direction: ${({ theme }) => theme.style.flexDirection.row};
        margin: 0 0 80px;
        padding: 1rem 2rem;
    }
`

export const SectionContainer = styled.div``

export const BannerArea = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex-direction: column;
    padding-bottom: 32px;
    border-bottom: ${({ theme }) => theme.typography.border.width.xxxThin} dotted #d3d3d3;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        border-bottom: none;
        max-width: 50%;
        border-right: ${({ theme }) => theme.typography.border.width.xxxThin} dotted #d3d3d3;
        margin: 16px 0 8px;
        padding-bottom: 0;
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        border-bottom: none;
        max-width: 50%;
        border-right: ${({ theme }) => theme.typography.border.width.xxxThin} dotted #d3d3d3;
        padding-bottom: 0;
    }

    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        border-bottom: none;
        border-right: ${({ theme }) => theme.typography.border.width.xxxThin} dotted #d3d3d3;
    }
`

export const Title = styled(H4).attrs({ as: 'h3' })`
    font: 700 25px/30px ${({ theme }) => theme.typography.font.family.inter};
    color: ${({ theme }) => theme.colors.tfwBlack99};
    text-align: center;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        text-align: left;
        margin: 0;
    }
`

export const Description = styled(CustomMarkdown)`
    font: 400 ${({ theme }) => theme.typography.font.size.small} / 22px
        ${({ theme }) => theme.typography.font.family.inter};
    color: ${({ theme }) => theme.colors.tfwBlack40};
    text-align: center;
    margin-top: 8px;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        text-align: left;
    }
`

export const ContactArea = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    margin-top: 16px;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        max-width: 50%;
        padding-left: 24px;
        margin: 16px 0 10px;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        padding-left: 48px;
    }
`

export const IconContainer = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
    align-items: baseline;
    margin-right: 12px;
`

const _Row = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
`

export const ContactTitle = styled(_Row)`
    font: 700 25px/30px ${({ theme }) => theme.typography.font.family.inter};
    color: ${({ theme }) => theme.colors.tfwBlack99};
    text-align: center;
    margin: 0 0 8px;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        text-align: left;
    }
`

export const ContactDescription = styled(_Row)`
    font: 400 ${({ theme }) => theme.typography.font.size.small} / 22px
        ${({ theme }) => theme.typography.font.family.inter};
    color: ${({ theme }) => theme.colors.tfwBlack40};
    text-align: center;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        text-align: left;
    }
`

export const PhoneRow = styled(_Row)`
    justify-content: left;
    padding: 8px 0;
`

export const EmailRow = styled(_Row)`
    justify-content: left;
    padding: 8px 0;
`
export const ButtonContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    height: 100%;
    align-items: end;
`

export const ButtonContainerText = styled.div`
    margin: 8px 0;
    font: 600 ${({ theme }) => theme.typography.font.size.small} / 24px
        ${({ theme }) => theme.typography.font.family.inter};
    text-align: center;
    color: ${({ theme }) => theme.colors.tfwBlack99};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: 8px 16px;
    }
`

export const TitleContainer = styled.div`
    margin: 16px 0 4px;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: 0 0 8px;
    }
`

export const ButtonInnerContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    width: 100%;
    margin: 8px 0;

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        padding: 0;
    }
`

export const ContactContainer = styled.div`
    margin: 16px 0 4px;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: 0 0 8px;
    }
`

export const ContactContentContainer = styled.div``
