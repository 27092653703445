import styled from 'styled-components'
import { desktopBreakpoint, tabletBreakpoint, wideScreenBreakpoint } from 'react-components'

export const Section = styled.div`
    padding: 16px;
    margin-top: 16px;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-top: 24px;
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin-top: 64px;
    }
    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        margin-top: 110px;
    }
`

export const SectionContent = styled.div`
    background: #6a476a;
    background-image: linear-gradient(to top left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
    border-radius: 20px;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        max-width: 1200px;
        margin: 0 auto;
        width: 100%;
        display: ${props => props.theme.style.display.flex};
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 16px;
    }
`
export const RentingPlatformContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    max-width: 1200px;
    margin: auto;
`
