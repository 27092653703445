import React, { FC, Fragment, memo, useContext } from 'react'
import { ScreenContext } from 'react-components'
import { ImageInnerContainer, Container, ImageLoader, ImageContainer } from './platform-styles'

import { items } from '../hero-section/hero-slider-items'

const PlatformContainer: FC = () => {
    const { isTablet } = useContext(ScreenContext)
    const _width = isTablet ? 86 : 115
    const _height = isTablet ? 26 : 36
    return (
        <Fragment>
            <Container>
                <ImageContainer>
                    {items.map((item, idx) => (
                        <ImageInnerContainer key={idx}>
                            <ImageLoader src={item.src} alt={item.alt} width={_width} height={_height} />
                        </ImageInnerContainer>
                    ))}
                </ImageContainer>
            </Container>
        </Fragment>
    )
}

export default memo(PlatformContainer)
