import styled from 'styled-components'
import { mobileBreakpoint, tabletBreakpoint } from 'react-components'

export const NameContainer = styled.div`
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        display: ${props => props.theme.style.display.flex};
        justify-content: space-between;
        > div:nth-child(1) {
            width: 29%;
        }
        > div:nth-child(2) {
            width: 69%;
        }
    }
`

export const CommunicationContainer = styled.div`
    margin-bottom: 16px;
`

export const InnerNameContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    justify-content: space-between;
    > div {
        width: 48%;
    }
`

export const ButtonText = styled.div`
    text-align: center;
    color: ${({ theme }) => theme.colors.tfwBlack99};
    font: 700 ${({ theme }) => theme.typography.font.size.medium} / 24px
        ${({ theme }) => theme.typography.font.family.inter};

    @media only screen and (max-width: ${mobileBreakpoint.max}px) {
        font-size: 1.15rem;
    }
`
