import styled from 'styled-components'
import { desktopBreakpoint, Markdown } from 'react-components'

export const H3 = styled.h3`
    font: ${({ theme }) =>
        `${theme.typography.font.yummo.weight.bold} 2.25rem / 2.625rem ${theme.typography.font.family.yummo}`};
    letter-spacing: -1px;
    color: ${({ theme }) => theme.colors.tfwBlack87};

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        font-size: ${({ theme }) => theme.typography.font.size.xxxLarge};
        line-height: 3.5rem;
        letter-spacing: -0.8px;
    }
`

export const H4 = styled.h4`
    font: ${({ theme }) =>
        `${theme.typography.font.yummo.weight.bold} ${theme.typography.font.size.xLarge} / 2rem ${theme.typography.font.family.yummo}`};
    letter-spacing: -0.28px;
    color: ${({ theme }) => theme.colors.tfwBlack87};

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        font-size: 2.25rem;
        line-height: 2.625rem;
        letter-spacing: -1px;
    }
`

export const H5 = styled.h5`
    font: ${({ theme }) =>
        `${theme.typography.font.lato.weight.bold} ${theme.typography.font.size.large} / 1.75rem ${theme.typography.font.family.lato}`};
    letter-spacing: -0.35px;
    color: ${({ theme }) => theme.colors.tfwBlack87};

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        font-size: ${({ theme }) => theme.typography.font.size.xLarge};
        line-height: 2rem;
        letter-spacing: -0.28px;
    }
`

export const H6 = styled.h6`
    font: ${({ theme }) =>
        `${theme.typography.font.lato.weight.bold} ${theme.typography.font.size.medium} / 1.5rem ${theme.typography.font.family.lato}`};
    color: ${({ theme }) => theme.colors.tfwBlack87};

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        font-size: ${({ theme }) => theme.typography.font.size.large};
        line-height: 1.75rem;
        letter-spacing: -0.35px;
    }
`

export const P = styled.p`
    font: ${({ theme }) =>
        `${theme.typography.font.lato.weight.regular} ${theme.typography.font.size.medium} / 1.5rem ${theme.typography.font.family.lato}`};
    color: ${({ theme }) => theme.colors.tfwBlack87};
`

export const CustomMarkdown = styled(Markdown)`
    font: ${({ theme }) =>
        `${theme.typography.font.lato.weight.regular} ${theme.typography.font.size.medium} / 1.5rem ${theme.typography.font.family.lato}`};
    color: ${props => props.theme.colors.tfwBlack87};

    & > p > a {
        color: ${props => props.theme.colors.tfwBlack87};
        font-weight: ${({ theme }) => theme.typography.font.lato.weight.regular};
    }
`
