import React, { FC, useContext } from 'react'
import { Circle, ScreenContext } from 'react-components'

import { NumberContainer } from './numbered-dot-styles'
import { is } from 'date-fns/locale'

export interface NumberedDotProps {
    number: number
    fillColor: string
    textColor?: string
    radius?: string
}

export const NumberedDot: FC<NumberedDotProps> = ({ number, fillColor, textColor, radius }) => {
    const { isMobile } = useContext(ScreenContext)
    const _radius = isMobile ? '20px' : '28px'
    return (
        <Circle radius={_radius} padding="0" fillColor={fillColor}>
            <NumberContainer textColor="#fff">{number}</NumberContainer>
        </Circle>
    )
}

export default NumberedDot
