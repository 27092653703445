import styled from 'styled-components'

interface SecondaryNavigationButtonItemProps {
    marginVertical?: string
    marginHorizontal?: string
    paddingVertical?: string
    paddingHorizontal?: string
}

export const StyledSecondaryNavigationButtonItem = styled.a<SecondaryNavigationButtonItemProps>`
    display: ${props => props.theme.style.display.flex};
    align-items: center;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.tfwBlack87};
    margin: ${props => props.marginVertical || 0} ${props => props.marginHorizontal || 0};
    padding: ${props => props.paddingVertical || 0} ${props => props.paddingHorizontal || 0};
    &:hover {
        background-color: #2298da0a;
        border-radius: ${({ theme }) => theme.typography.border.radius.small};
    }
`

export const TextContainer = styled.div`
    margin-left: ${props => props.theme.spacing.medium};
`

export const IconContainer = styled.div`
    width: ${({ theme }) => theme.layout.xSmall};
    height: ${({ theme }) => theme.layout.xSmall};
`
