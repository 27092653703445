import React, { FC, memo, MouseEventHandler, useCallback, useContext } from 'react'

import { AnalyticsService } from '@src/services'
import { homePageEventCategories } from '@src/services/analytics-service/categories'
import { CustomerSupportButtonTransport } from '@common/transports/home-page-transport'
import { CustomerSupportButtonLink, ButtonContainer } from './customer-support-button-styles'
import { ScreenContext } from 'react-components'

interface Props {
    onClick?: MouseEventHandler<HTMLElement>
    item: CustomerSupportButtonTransport
}

const CustomerSupportButton: FC<Props> = ({ item }) => {
    const { isMobile } = useContext(ScreenContext)

    const _handleClick = useCallback(() => {
        if (item.button.eventLabel) {
            AnalyticsService.trackClickEvent(homePageEventCategories.CUSTOMER_SUPPORT_SECTION, item.button.eventLabel)
        }
    }, [item.button.eventLabel])

    return (
        <ButtonContainer>
            <CustomerSupportButtonLink onClick={_handleClick} href={item.button.url}>
                {item.button.text}
            </CustomerSupportButtonLink>
        </ButtonContainer>
    )
}

export default memo(CustomerSupportButton)
