import styled from 'styled-components'
import { desktopBreakpoint, tabletBreakpoint } from 'react-components'

import { H3, P } from '@src/components/common/styles'

export const Container = styled.div`
    padding: 16px;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        max-width: 1200px;
        padding: 0 16px;
        margin: 3rem auto 2.5rem;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin-top: 6rem;
    }
`

export const TitleContainer = styled.div`
    text-align: center;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-bottom: 24px;
    }
`

export const Title = styled(H3).attrs({ as: 'h1' })`
    font: 700 25px/30px ${({ theme }) => theme.typography.font.family.inter};
    color: ${({ theme }) => theme.colors.tfwBlack99};
    margin-top: 1rem;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: 32px;
        line-height: 40px;
    }
`

export const Subtitle = styled(P)`
    margin: 8px auto;
    font: 400 ${({ theme }) => theme.typography.font.size.small} / 22px
        ${({ theme }) => theme.typography.font.family.inter};
    color: ${({ theme }) => theme.colors.tfwBlack40};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: 16px;
        line-height: 24px;
        margin: 8px auto 0;
    }
`

export const ComparisonImageContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    max-width: 1200px;
    margin: auto;
`

export const Image = styled.img`
    margin: 0.625rem;
    width: 100%;
`
