import React, { FC, memo, useContext } from 'react'
import { HowItWorksStepTransport } from '@common/transports/home-page-transport'
import { HowItWorksStepCardContainer, HowItWorksStepContainer, NumberedDotContainer } from './how-it-works-step-styles'
import { NumberedDot } from './numbered-dot'
import HowItWorksStepCard from './how-it-works-step-card'

interface Props {
    step: HowItWorksStepTransport
    stepNumber: number
    isFirstStep: boolean
    isLastStep: boolean
}

const HowItWorksStep: FC<Props> = ({ step, stepNumber, isFirstStep, isLastStep }) => {
    const _fillColor = isFirstStep ? '#64B6E7' : isLastStep ? '#E45F75' : '#FDC400'
    return (
        <HowItWorksStepContainer>
            <NumberedDotContainer>
                <NumberedDot number={stepNumber} fillColor={_fillColor} />
            </NumberedDotContainer>
            <HowItWorksStepCardContainer>
                <HowItWorksStepCard step={step} />
            </HowItWorksStepCardContainer>
        </HowItWorksStepContainer>
    )
}

export default memo(HowItWorksStep)
