import React, { FC, memo } from 'react'

import { SectionContainer, Description, Section, SectionContent, Title, TitleContent } from './styles'
import HowItWorksSteps from './how-it-works-steps'
import { HowItWorksSectionTransport } from '@common/transports/home-page-transport'

interface Props {
    howItWorksSection: HowItWorksSectionTransport
    isLoggedIn: boolean
    isRegistrationFormVisible: boolean
}

const HowItWorksSection: FC<Props> = ({
    howItWorksSection: { id, title, subtitle, steps, registerButton, dashboardButton },
    isLoggedIn,
    isRegistrationFormVisible = false,
}) => (
    <SectionContainer id={id}>
        <Section>
            <SectionContent>
                <TitleContent>
                    <Title>{title}</Title>
                    <Description>{subtitle}</Description>
                </TitleContent>
                <HowItWorksSteps
                    isRegistrationFormVisible={isRegistrationFormVisible}
                    steps={steps}
                    registerButton={registerButton}
                    dashboardButton={dashboardButton}
                    isLoggedIn={isLoggedIn}
                />
            </SectionContent>
        </Section>
    </SectionContainer>
)

export default memo(HowItWorksSection)
