import React, { useContext, memo } from 'react'
import {
    ModalContainer,
    CloseButton,
    ModalTitle,
    Description,
    DeactivateParagraph,
    StickyTopBar,
    FairnessPrincipleBulletPointList,
    FairnessPrincipleBulletPointListItem,
    FairnessPrincipleExampleContainer,
    FairnessPrincipleExampleList,
} from './additional-policies-section-modal-styles'
import CloseIcon from '../../../assets/images/modal-cross-icon.svg'
import { ScreenContext } from 'react-components'
import { FairnessPrincipleModalTransport } from '@common/transports/home-page-transport'

type Props = {
    onClick: () => void
    modalSection: FairnessPrincipleModalTransport
}

const FairnessPrincipleModal: React.FC<Props> = ({
    onClick,
    modalSection: { title, description, deactivationDescription, example },
}) => {
    const { isMobile } = useContext(ScreenContext)
    return (
        <ModalContainer>
            {isMobile ? (
                <StickyTopBar>
                    <CloseButton onClick={onClick}>
                        <CloseIcon />
                    </CloseButton>
                </StickyTopBar>
            ) : (
                <CloseButton onClick={onClick}>
                    <CloseIcon />
                </CloseButton>
            )}
            <ModalTitle>{title}</ModalTitle>
            <Description inputText={description.description} escapeHtml />
            <FairnessPrincipleBulletPointList>
                {description.criteria.map((criteria, index) => (
                    <FairnessPrincipleBulletPointListItem key={index + 1}>
                        {criteria.description}
                    </FairnessPrincipleBulletPointListItem>
                ))}
            </FairnessPrincipleBulletPointList>
            <DeactivateParagraph inputText={deactivationDescription} escapeHtml />
            <FairnessPrincipleExampleContainer>
                {example.map((example, index) => (
                    <FairnessPrincipleExampleList inputText={example.description} key={index + 1} escapeHtml />
                ))}
            </FairnessPrincipleExampleContainer>
        </ModalContainer>
    )
}

export default memo(FairnessPrincipleModal)
