import React, { memo, useCallback, useContext } from 'react'
import { useTranslation } from '@src/hooks/locale'

import {
    Container,
    Description,
    SectionContent,
    Title,
    PremiumPartnershipIconContainer,
    PremiumPartnershipText,
    TitleContainer,
    ButtonContainer,
    ButtonOuterContainer,
    TickIcon,
    ButtonContainerText,
    PremiumPartnershipIcon,
    IconContainer,
    ItemContainer,
    RegisterButton,
} from './premium-partnership-styles'

import { AnalyticsService } from '@src/services'
import { homePageEventCategories } from '@src/services/analytics-service/categories'
import { homePageEventLabels } from '@src/services/analytics-service/labels'
import { PREMIUM_PARTNERSHIP_ITEMS } from '@server/constants'

const PremiumPartnershipContainer = () => {
    const _handleButtonClick = useCallback(() => {
        AnalyticsService.trackClickEvent(
            homePageEventCategories.CANARY_HO,
            homePageEventLabels.premiumPartnershipSection.BUTTON,
        )
    }, [])

    const { t } = useTranslation()

    return (
        <Container>
            <PremiumPartnershipIconContainer>
                <PremiumPartnershipIcon />
            </PremiumPartnershipIconContainer>
            <TitleContainer>
                <Title>{t('premiumPartnershipTitle')}</Title>
                <Description>{t('premiumPartnershipDescription')}</Description>
            </TitleContainer>
            <SectionContent>
                {PREMIUM_PARTNERSHIP_ITEMS.map(item => (
                    <PremiumPartnershipText key={item.key}>
                        <ItemContainer>
                            <IconContainer>
                                <TickIcon />
                            </IconContainer>
                        </ItemContainer>
                        <ItemContainer>{t(item.key)}</ItemContainer>
                    </PremiumPartnershipText>
                ))}
            </SectionContent>
            <ButtonOuterContainer>
                <ButtonContainer>
                    <RegisterButton href="#registration-form" onClick={_handleButtonClick}>
                        <ButtonContainerText>{t('premiumPartnershipButton')}</ButtonContainerText>
                    </RegisterButton>
                </ButtonContainer>
            </ButtonOuterContainer>
        </Container>
    )
}

export default memo(PremiumPartnershipContainer)
