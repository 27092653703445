import React, { FC, memo, useContext, useCallback } from 'react'
import { useTranslation } from '@src/hooks/locale'

import {
    BenefitContainer,
    Container,
    Description,
    OuterIconContainer,
    IconContainer,
    Title,
    BenefitSection,
    MLW1Icon,
    MLW2Icon,
    MLW3Icon,
    MLW4Icon,
    MLW5Icon,
    OuterButtonContainer,
    ButtonContainer,
    ButtonTextContainer,
    RegisterButton,
} from './mlw-benefits-styles'

import { ScreenContext } from 'react-components'
import { AnalyticsService } from '@src/services'
import { homePageEventCategories } from '@src/services/analytics-service/categories'
import { homePageEventLabels } from '@src/services/analytics-service/labels'
import { MULTILINGUAL_WEBSITE_ITEMS } from '@server/constants'

const MLWBenefits: FC = () => {
    const { isMobile } = useContext(ScreenContext)
    const _handleButtonClick = useCallback(() => {
        AnalyticsService.trackClickEvent(homePageEventCategories.CANARY_HO, homePageEventLabels.mlwSection.BUTTON)
    }, [])

    const { t } = useTranslation()

    const _getIconComponent = (iconName: string) => {
        switch (iconName) {
            case 'internationMLWBenefit':
                return <MLW1Icon />
            case 'profitMLWBenefit':
                return <MLW2Icon />
            case 'multilingualMLWBenefit':
                return <MLW3Icon />
            case 'visitorMLWBenefit':
                return <MLW4Icon />
            case 'promoteMLWBenefit':
                return <MLW5Icon />
        }
    }

    return (
        <Container>
            {MULTILINGUAL_WEBSITE_ITEMS.map(item => (
                <BenefitContainer key={item.key}>
                    <OuterIconContainer>
                        <IconContainer>{_getIconComponent(item.key)}</IconContainer>
                    </OuterIconContainer>
                    <BenefitSection>
                        <Title>{t(item.key + 'Title')}</Title>
                        <Description inputText={t(item.key + 'Description')} escapeHtml />
                    </BenefitSection>
                </BenefitContainer>
            ))}
            {isMobile && (
                <OuterButtonContainer>
                    <ButtonContainer>
                        <RegisterButton href="#registration-form" onClick={_handleButtonClick}>
                            <ButtonTextContainer>+ {t('mlwBenefitButton')}</ButtonTextContainer>
                        </RegisterButton>
                    </ButtonContainer>
                </OuterButtonContainer>
            )}
        </Container>
    )
}

export default memo(MLWBenefits)
