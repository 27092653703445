import React, { FC, memo } from 'react'

import { Container, MLWBenefitsContainer, MLWImageContainer, InnerContainer } from './multilingual-website-styles'

import MLWBenefits from './mlw-benefits'
import MLWImages from './mlw-images'

const MultilingualWebsite: FC = () => {
    return (
        <Container>
            <InnerContainer>
                <MLWBenefitsContainer>
                    <MLWBenefits />
                </MLWBenefitsContainer>
                <MLWImageContainer>
                    <MLWImages />
                </MLWImageContainer>
            </InnerContainer>
        </Container>
    )
}

export default memo(MultilingualWebsite)
