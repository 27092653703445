import styled from 'styled-components'
import { mobileBreakpoint, tabletBreakpoint } from 'react-components'
import { CustomMarkdown } from '@src/components/common/styles'

export const ModalContainer = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    position: ${({ theme }) => theme.style.position.relative};
    padding: ${({ theme }) => theme.spacing.xxxLarge} ${({ theme }) => theme.spacing.medium}
        ${({ theme }) => theme.spacing.large};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: ${({ theme }) => theme.spacing.xLarge};
    }
`

export const CloseButton = styled.a`
    position: ${({ theme }) => theme.style.position.absolute};
    top: 1.125rem;
    cursor: pointer;
    width: 0.75rem;
    height: 0.75rem;
    margin: -0.25rem 0 0 -0.3125rem;

    @media only screen and (max-width: ${mobileBreakpoint.max}px) {
        left: 1rem;
    }

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        top: 2.625rem;
        right: 2.375rem;
    }
`

export const ModalTitle = styled.h5`
    font-family: ${({ theme }) => theme.typography.font.family.inter};
    font-style: normal;
    font-weight: ${({ theme }) => theme.typography.font.yummo.weight.bold};
    font-size: ${({ theme }) => theme.typography.font.size.xLarge};
    line-height: 2rem;
    letter-spacing: -0.0175rem;
    margin: 0 0 ${({ theme }) => theme.spacing.medium};
    color: ${({ theme }) => theme.colors.tfwBlack87};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-right: 1.5rem;
    }
`

const _ModalBody = styled(CustomMarkdown)`
    font-family: ${({ theme }) => theme.typography.font.family.lato};
    font-style: normal;
    font-weight: ${({ theme }) => theme.typography.font.lato.weight.regular};
    font-size: ${({ theme }) => theme.typography.font.size.medium};
    line-height: 1.5rem;
    color: ${({ theme }) => theme.colors.tfwBlack87};
    margin: 0 0 ${({ theme }) => theme.spacing.small};
`

export const Description = styled(_ModalBody)`
    font-family: ${({ theme }) => theme.typography.font.family.inter};
`

export const DreamIndexCriteria = styled(_ModalBody)`
    font-family: ${({ theme }) => theme.typography.font.family.inter};
`

export const DeactivateParagraph = styled(CustomMarkdown)`
    font-family: ${({ theme }) => theme.typography.font.family.inter};
    font-size: ${({ theme }) => theme.typography.font.size.medium};
    line-height: 1.5rem;
    margin: 0;
    & > p > a {
        color: ${props => props.theme.colors.tfwBlack87};
        font-weight: ${({ theme }) => theme.typography.font.lato.weight.bold};
    }
`

export const BulletPointContainer = styled.div`
    margin: ${({ theme }) => theme.spacing.xxSmall} 0;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: ${({ theme }) => theme.spacing.xxSmall} 0 ${({ theme }) => theme.spacing.xxSmall} 0.1875rem;
    }
`

export const BulletPointBody = styled(CustomMarkdown)`
    font-family: ${({ theme }) => theme.typography.font.family.inter};
    font-style: normal;
    font-weight: ${({ theme }) => theme.typography.font.lato.weight.regular};
    font-size: ${({ theme }) => theme.typography.font.size.medium};
    line-height: 1.5rem;
    color: ${({ theme }) => theme.colors.tfwBlack87};
`

export const StickyTopBar = styled.div`
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 2.5rem;
    background: white;
`

export const FairnessPrincipleBulletPointList = styled.ul`
    padding: 0 ${({ theme }) => theme.spacing.xLarge} ${({ theme }) => theme.spacing.small};
    list-style: none;
`

export const FairnessPrincipleBulletPointListItem = styled.li`
    font-family: ${({ theme }) => theme.typography.font.family.inter};
    font-style: normal;
    font-weight: ${({ theme }) => theme.typography.font.lato.weight.regular};
    font-size: ${({ theme }) => theme.typography.font.size.medium};
    line-height: 1.5rem;
    color: ${({ theme }) => theme.colors.tfwBlack87};
    &:before {
        content: '•';
        font-size: 1.2rem;
        padding-right: 1rem;
    }
`

export const FairnessPrincipleExampleContainer = styled.div`
    font-family: ${({ theme }) => theme.typography.font.family.inter};
    font-style: normal;
    font-weight: ${({ theme }) => theme.typography.font.lato.weight.regular};
    font-size: ${({ theme }) => theme.typography.font.size.medium};
    line-height: 1.5rem;
    color: ${({ theme }) => theme.colors.tfwBlack87};
    margin: 0 0 ${({ theme }) => theme.spacing.small};
`

export const FairnessPrincipleExampleList = styled(CustomMarkdown)`
    font-family: ${({ theme }) => theme.typography.font.family.inter};
    padding: ${({ theme }) => theme.spacing.xSmall};
    margin: ${({ theme }) => theme.spacing.xSmall} 0 0;
    border: ${({ theme }) => theme.typography.border.width.xxxThin}
        ${({ theme }) => theme.typography.border.style.solid} #e45f75cc;
    border-radius: ${({ theme }) => theme.typography.border.radius.medium};
`
