import React, { FC, Fragment, useCallback } from 'react'
import { Collapsible, CollapsibleContent, CollapsibleHeader } from 'react-components'

import {
    Container,
    Description,
    DownArrowIcon,
    OffsetContainer,
    Title,
    TitleContainer,
    UpArrowIcon,
} from './faq-item-styles'
import { AnalyticsService } from '@src/services'
import { homePageEventCategories } from '@src/services/analytics-service/categories'
import { FAQItemTransport } from '@common/transports/home-page-transport'

type Props = FAQItemTransport

const FAQItem: FC<Props> = ({ id, title, description, eventLabel }) => {
    const _handleFAQTitleClick = useCallback(() => {
        if (eventLabel) {
            AnalyticsService.trackClickEvent(homePageEventCategories.FAQ_SECTION, eventLabel)
        }
    }, [eventLabel])

    return (
        <OffsetContainer id={id}>
            <Container>
                <Collapsible initialOpen={false} transitionTime={300}>
                    {({ isOpened }) => (
                        <Fragment>
                            <CollapsibleHeader>
                                <TitleContainer onClick={_handleFAQTitleClick}>
                                    <Title>{title}</Title>
                                    {isOpened ? <UpArrowIcon alt="Up Logo" /> : <DownArrowIcon alt="Down Logo" />}
                                </TitleContainer>
                            </CollapsibleHeader>
                            <CollapsibleContent>
                                <Description inputText={description} escapeHtml />
                            </CollapsibleContent>
                        </Fragment>
                    )}
                </Collapsible>
            </Container>
        </OffsetContainer>
    )
}

export default FAQItem
