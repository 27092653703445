import styled from 'styled-components'
import { desktopBreakpoint, tabletBreakpoint, wideScreenBreakpoint } from 'react-components'

import { BackIcon } from '@src/components/common/icons'

export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 12px 16px;
    box-shadow: inset 0 -0.0625rem 0 #0000001e;
    z-index: 1002;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: 16px 22px;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        padding: 16px;
    }
`

export const ContentContainer = styled.div`
    width: inherit;
    display: ${props => props.theme.style.display.flex};
    justify-content: space-between;
    align-items: center;
    min-height: ${({ theme }) => theme.layout.small};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        max-width: 1200px;
        margin: 0 auto;
    }
`

export const LeftContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    align-items: center;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) and (max-width: ${tabletBreakpoint.max}px) {
        justify-content: space-between;
        flex: 1;
    }
`

export const ImageContainer = styled.a`
    width: 98px;
    height: 32px;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        width: 148px;
        height: 48px;
    }
`

export const Image = styled.img`
    width: ${({ theme }) => theme.layout.fill};
    height: ${({ theme }) => theme.layout.fill};
`

export const RightContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    align-items: center;
`

export const ButtonContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    align-items: center;
    justify-content: center;
`

export const ButtonContentContainer = styled.div`
    padding: ${props => props.theme.spacing.xSmall} 0;
    font: ${({ theme }) => `bold ${theme.typography.font.size.small} / 1.5rem
        ${theme.typography.font.family.lato}`};
`

export const HeaderButtonContentContainer = styled(ButtonContentContainer)`
    padding: 3px 8px;
    font: 600 ${({ theme }) => theme.typography.font.size.small} / 24px
        ${({ theme }) => theme.typography.font.family.inter};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: 4px 12px;
    }
`

export const NavigationIconContainer = styled.div`
    margin-left: ${props => props.theme.spacing.medium};
    cursor: pointer;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        display: none;
    }
`

export const LowerContentContainer = styled.div`
    padding-bottom: 0.625rem;
`

export const SidebarButtonContainer = styled(ButtonContainer)`
    padding: 0.625rem 1.25rem;
    text-align: center;
`

export const SidebarContentContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex-direction: column;
    justify-content: space-between;
    height: ${({ theme }) => theme.layout.fill};
`

export const SidebarContentInnerContainer = styled.div``

export const SidebarLogoContainer = styled.div`
    padding: ${({ theme }) =>
        `${theme.spacing.small} ${theme.spacing.small} ${theme.spacing.small} ${theme.spacing.large}`};
    display: ${props => props.theme.style.display.flex};
    justify-content: space-between;
    align-items: center;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: 1.25rem ${props => props.theme.spacing.xlarge};
    }
`

export const SecondaryNavigationButtonContainer = styled.div`
    padding: 0 ${props => props.theme.spacing.large};
    border: solid #0000001e;
    border-width: 0.0625rem 0;
    font: ${({ theme }) =>
        `${theme.typography.font.weight.normal} ${theme.typography.font.size.medium} / ${theme.typography.font.size.xLarge} ${theme.typography.font.family.lato}`};
    color: ${({ theme }) => theme.colors.tfwBlack87};
`

export const SecondaryNavigationButtonTextContainer = styled.div`
    margin-top: ${props => props.theme.spacing.medium};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-top: 1.125rem;
    }
`

export const IconContainer = styled.div`
    cursor: pointer;
    display: ${props => props.theme.style.display.flex};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: ${props => props.theme.spacing.xSmall};
    }
`

export const NavigationContainer = styled.div`
    display: none;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        display: ${props => props.theme.style.display.flex};
    }
`

export const PrimaryNavigationContainer = styled.div`
    display: none;
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        display: ${props => props.theme.style.display.flex};
    }
`

export const Line = styled.div`
    display: none;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) and (max-width: ${tabletBreakpoint.max}px) {
        display: unset;
        background-color: ${({ theme }) => theme.colors.tfwBlack12};
        height: 2.5rem;
        width: 1px;
        margin: ${({ theme }) => `0 ${theme.spacing.medium} 0 ${theme.spacing.large}`};
    }
`

export const SecondaryNavigationItemContainer = styled.div`
    color: ${({ theme }) => theme.colors.tfwBlack87};
    display: ${props => props.theme.style.display.flex};
    align-items: center;
    cursor: pointer;
    font: 600 ${({ theme }) => theme.typography.font.size.small} / 24px
        ${({ theme }) => theme.typography.font.family.inter};
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin-right: 40px;
    }
`

export const GhostButtonContainer = styled.div`
    display: none;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        display: ${props => props.theme.style.display.flex};
        align-items: center;
        justify-content: center;
    }
`

export const GhostButtonContainerV2 = styled.div`
    max-width: 140px;
    white-space: normal;
    word-wrap: break-word;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        display: ${props => props.theme.style.display.flex};
        align-items: center;
        justify-content: center;
    }
`

export const SolidButtonContainer = styled(ButtonContainer)`
    margin-left: ${props => props.theme.spacing.medium};
    color: ${({ theme }) => theme.colors.tfwBlack87};
`

export const BackButton = styled.a`
    cursor: pointer;
    margin-top: 0.3rem;
`

export const BackIconContainer = styled(BackIcon)`
    height: 1.25rem;
    width: 1.25rem;
`

export const AdvertisementContainer = styled.a`
    position: fixed;
    display: flex;
    top: 56px;
    left: 0;
    right: 0;
    color: white;
    background: black;
    text-align: center;
    padding: 12px 16px;
    box-shadow: inset 0 -0.0625rem 0 #0000001e;
    text-decoration: none;
    z-index: 1001;
    font: 400 12px/20px ${({ theme }) => theme.typography.font.family.inter};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: 16px 22px;
        margin: 0 auto;
        top: 80px;
        font-size: 16px;
    }

    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        padding: 20px;
        top: 88px;
    }
`

export const AdvertisementContent = styled.p`
    margin: auto;
    font-weight: 900;
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        flex-direction: row;
    }
`

export const AdvertisementTitle = styled.p`
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin-right: 5px;
    }
`

export const AdvertisementDescription = styled.p`
    color: #ffc74e;
`
