import { createSelector } from 'reselect'

import State, { NotificationState } from './types'
import RootState from '../types'

const _getNotificationChannel = (state: RootState): State => state.notificationChannel

export const getNotifications = createSelector<RootState, State, NotificationState[]>(
    _getNotificationChannel,
    notificationChannel => notificationChannel.messages,
)
