import { registrationFormEventLabels } from '../labels'

const getRegistrationFormFields = () => {
    return [
        registrationFormEventLabels.field.SALUTATION,
        registrationFormEventLabels.field.FIRST_NAME,
        registrationFormEventLabels.field.LAST_NAME,
        registrationFormEventLabels.field.EMAIL,
        registrationFormEventLabels.field.PHONE,
    ]
}

export default getRegistrationFormFields
