import styled, { AnyStyledComponent } from 'styled-components'
import { Image } from 'pure-react-carousel'
import { desktopBreakpoint, tabletBreakpoint, wideScreenBreakpoint } from 'react-components'

import { P } from '@src/components/common/styles'
import FullStar from '../../../assets/images/full-green-star.svg'
import EmptyStar from '../../../assets/images/empty-green-star.svg'
import config from '@common/config'

export const CardContent = styled.div`
    padding: ${({ theme }) => theme.spacing.medium};
    background-color: #f5f5f5;
    border-radius: 1rem;
    display: ${props => props.theme.style.display.flex};
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: auto;
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        max-width: 588px;
        padding: 1.5rem;
    }
`

export const PictureContainer = styled.div`
    margin: auto;
    width: 100%;

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin: 0;
        width: 200px;
        margin-left: 1rem;
    }
`

interface TestimonialImageProps {
    width: number
    height: number
}

export const TestimonialImage = styled(Image).attrs<TestimonialImageProps>(({ width, height }) => ({
    loading: 'lazy',
    width,
    height,
}))<TestimonialImageProps>`
    border-radius: 8px;
    margin-bottom: ${({ theme }) => theme.spacing.small};
    max-height: 182px;
`

export const PictureSection = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex-direction: column;
    justify-content: space-around;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        justify-content: start;
    }
`

export const RatingReviewContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex-direction: column;
    justify-content: space-around;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        justify-content: start;
    }
`

export const TitleRatingContainer = styled.div`
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        display: ${props => props.theme.style.display.flex};
        flex-direction: column;
    }
`

export const PictureRatingContainer = styled.div`
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        display: ${props => props.theme.style.display.flex};
        flex-direction: row;
        margin-bottom: ${({ theme }) => theme.spacing.large};
    }
`

export const Title = styled(P)`
    font: 700 ${({ theme }) => theme.typography.font.size.medium} / 24px
        ${({ theme }) => theme.typography.font.family.inter};
    color: ${({ theme }) => theme.colors.tfwBlack99};
`

export const Subtitle = styled(P)`
    font: 400 ${({ theme }) => theme.typography.font.size.small} / 22px
        ${({ theme }) => theme.typography.font.family.inter};
    color: ${({ theme }) => theme.colors.tfwBlack40};
    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        line-height: 1.5rem;
    }
`

export const RatingContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    align-items: center;
    margin: ${({ theme }) => theme.spacing.small} 0;

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin: 0 0 ${({ theme }) => theme.spacing.small};
    }
`
export const FullStarIcon = styled(FullStar as AnyStyledComponent)`
    margin: 0 0.2rem;
`

export const EmptyStarIcon = styled(FullStarIcon).attrs({ as: EmptyStar })``

// For some reason, using a component for HalfStar does not work. Hence, using the image for now.
export const HalfStarIcon = styled(FullStarIcon).attrs({
    as: 'img',
    src: `${config.basePath}/assets/images/half-green-star.svg`,
})``

export const ReviewText = styled(P)`
    font: 400 ${({ theme }) => theme.typography.font.size.medium} / 24px
        ${({ theme }) => theme.typography.font.family.inter};
    color: #000000;
`

export const TitleContainer = styled.div`
    margin-top: 16px;
`

export const Container = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex-direction: column;
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        flex-direction: row-reverse;
    }
`

export const ReadMoreLink = styled.p`
    font: 400 ${({ theme }) => theme.typography.font.size.medium} / 24px
        ${({ theme }) => theme.typography.font.family.inter};
    color: #000000;
    display: inline;
`
