import React, { FC, memo, MouseEventHandler, useCallback } from 'react'

import ButtonTransport from '@common/transports/common/button-transport'
import {
    IconContainer,
    StyledSecondaryNavigationButtonItem,
    TextContainer,
} from './secondary-navigation-button-item-styles'
import Phone from '../../../assets/images/phone-outgoing.svg'
import Email from '../../../assets/images/mail.svg'
import { AnalyticsService } from '@src/services'
import { homePageEventCategories } from '@src/services/analytics-service/categories'

interface Props {
    onClick?: MouseEventHandler<HTMLElement>
    item: ButtonTransport
    marginVertical?: string
    marginHorizontal?: string
    paddingVertical?: string
    paddingHorizontal?: string
}

const getIcon = (itemType?: string) => {
    if (itemType === 'email') {
        return <Email />
    } else if (itemType === 'phone') {
        return <Phone />
    } else {
        return null
    }
}

const SecondaryNavigationButtonItem: FC<Props> = ({
    item,
    marginVertical,
    marginHorizontal,
    paddingVertical,
    paddingHorizontal,
}) => {
    const _handleClick = useCallback(() => {
        if (item.eventLabel) {
            AnalyticsService.trackClickEvent(homePageEventCategories.HEADER_SECTION, item.eventLabel)
        }
    }, [item.eventLabel])

    return (
        <StyledSecondaryNavigationButtonItem
            href={item.url}
            marginVertical={marginVertical}
            marginHorizontal={marginHorizontal}
            paddingVertical={paddingVertical}
            paddingHorizontal={paddingHorizontal}
            onClick={_handleClick}
        >
            <IconContainer>{getIcon(item?.type)}</IconContainer>
            <TextContainer>{item.text}</TextContainer>
        </StyledSecondaryNavigationButtonItem>
    )
}

export default memo(SecondaryNavigationButtonItem)
