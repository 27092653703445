import styled from 'styled-components'
import { desktopBreakpoint, tabletBreakpoint } from 'react-components'
import Image from 'next/image'

export const ImageLoader = styled(Image).attrs({
    loading: 'lazy',
})`
    display: block;
    width: 100%;
    height: 100%;
`

export const ImageSliderLoader = styled(Image).attrs({
    loading: 'lazy',
})`
    width: 100%;
    height: 100%;
    display: block;
`

export const ImageContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        width: 100%;
    }
`

export const ImageSliderContainer = styled.div`
    height: 100%;
    width: 100%;
    margin-left: 22px;
`

export const Container = styled.div`
    display: ${props => props.theme.style.display.flex};
    justify-content: space-around;
    margin: auto;
    width: 84%;
    background-color: #f9f5ff;
    border-radius: 0px 0px 16px 16px;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        width: 92%;
    }
`

export const SliderContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    justify-content: space-around;
    margin: auto;
    background-color: #f9f5ff;
    padding: 24px 16px;
    border-radius: 16px;
`

export const ImageInnerContainer = styled.div`
    width: 115.2px;
    margin: 24px 21px;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        width: 86px;
        margin: 24px 12px;
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        width: 115.2px;
        margin: 24px 36px;
    }
`
