import React, { FC, memo } from 'react'

import { SectionContainer, Description, Section, SectionContent, Title, TitleContainer } from './styles'
import MultilingualWebsite from './multilingual-website'
import { useTranslation } from '@src/hooks/locale'

const MultilingualWebsiteSection: FC = () => {
    const { t } = useTranslation()
    return (
        <SectionContainer id="mlw">
            <Section>
                <SectionContent>
                    <TitleContainer>
                        <Title>{t('mlwTitle')}</Title>
                        <Description>{t('mlwDescription')}</Description>
                    </TitleContainer>
                    <MultilingualWebsite />
                </SectionContent>
            </Section>
        </SectionContainer>
    )
}

export default memo(MultilingualWebsiteSection)
