import React, { FC, memo } from 'react'

import { Container, ImageContainer, ContentContainer, InnerContainer, ImageLoader } from './mlw-images-styles'
import { MULTILINGUAL_WEBSITE_COUNTRIES } from '@server/constants'
import FranceImage from '../../../assets/picture/france.png'
import NetherlandImage from '../../../assets/picture/netherland.png'
import ItalyImage from '../../../assets/picture/italy.png'
import SpainImage from '../../../assets/picture/spain.png'

const MLWImages: FC = () => {
    const getIconComponent = (iconName: string) => {
        switch (iconName) {
            case 'France':
                return <ImageLoader src={FranceImage} alt="France image" />
            case 'Netherland':
                return <ImageLoader src={NetherlandImage} alt="Netherland image" />
            case 'Italy':
                return <ImageLoader src={ItalyImage} alt="Italy image" />
            case 'Spain':
                return <ImageLoader src={SpainImage} alt="Spain image" />
        }
    }

    return (
        <Container>
            <InnerContainer>
                <ContentContainer>
                    {MULTILINGUAL_WEBSITE_COUNTRIES.map(country => (
                        <ImageContainer key={country.title}>{getIconComponent(country.title)}</ImageContainer>
                    ))}
                </ContentContainer>
            </InnerContainer>
        </Container>
    )
}

export default memo(MLWImages)
