import { useCallback, useContext } from 'react'

import { TranslationFunction } from '@common/types'
import { LocaleContext } from '../context/locale'
import { defaultMessages } from '../i18n/config'

export const useLocale = () => useContext(LocaleContext)

export const useTranslation = () => {
    const { locale, messages } = useLocale()

    const t: TranslationFunction = useCallback(
        key => {
            if (messages[key] === undefined || messages[key] === null) {
                console.warn({ message: 'Translation for locale not found.', key, locale })
            }
            return messages[key] || defaultMessages[key] || ''
        },
        [locale, messages],
    )

    return { t }
}
