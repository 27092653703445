import React from 'react'
import { BulletPointBox, theme } from 'react-components'
import { StyledListingCriteria } from './additional-policies-section-styles'

type Props = {
    index: number
}

const ListingCriteriaItem: React.FC<Props> = ({ index, children }) => (
    <StyledListingCriteria>
        <BulletPointBox
            index={index}
            backgroundColor="#fdc40014"
            borderColor="#fdc40080"
            borderRadius="0.5rem"
            bulletPointBackgroundColor={theme.colors.tfwYellow}
        >
            {children}
        </BulletPointBox>
    </StyledListingCriteria>
)

export default ListingCriteriaItem
