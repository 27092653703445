import styled, { css, keyframes } from 'styled-components'
import { desktopBreakpoint, tabletBreakpoint, Markdown, wideScreenBreakpoint } from 'react-components'
import { H3 } from '@src/components/common/styles'
import Image from 'next/image'

interface RegistrationFormWrapper {
    src?: string
}
export const RegistrationFormWrapper = styled.div<RegistrationFormWrapper>`
    display: ${props => props.theme.style.display.flex};
    justify-content: center;
    align-items: center;
`

const glowAnimation = keyframes`
    0% {
        box-shadow: 0 0 5px rgba(253, 196, 0, 0.7), 0 0 10px rgba(253, 196, 0, 0.5);
    }
    50% {
        box-shadow: 0 0 20px rgba(253, 196, 0, 1), 0 0 30px rgba(253, 196, 0, 0.7);
    }
    100% {
        box-shadow: 0 0 5px rgba(253, 196, 0, 0.7), 0 0 10px rgba(253, 196, 0, 0.5);
    }
`

export const Container = styled.div<{ isGlowing: boolean }>`
    background: linear-gradient(200.75deg, #fdc400 2.29%, #64b6e7 38.05%, #e45f75 93.97%);
    padding: 2px;
    border-radius: 24px;
    margin: auto;

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        display: inline-block;
        width: 30rem;
        margin-top: 16px;
    }

    ${props =>
        props.isGlowing &&
        css`
            animation: ${glowAnimation} 1s infinite alternate; // Repeat the animation
        `}
`
export const OuterContainer = styled.div<{ src: string }>`
    background-image: url(${props => props.src});
    background-repeat: no-repeat;
    display: ${props => props.theme.style.display.flex};
    flex: 1;
    align-items: center;
    background-position: center top;
    background-size: 100%;
    justify-content: center;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        background-position: 70% 60%;
        background-size: 480px;
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        background-position: 70% 65%;
        margin-left: 10px;
    }

    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        background-position: 70% 65%;
        margin-left: 57px;
    }
`

export const InnerContainer = styled.div`
    padding: 16px;
    width: 100%;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: 20px;
        width: unset;
    }
`

export const ImageContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    margin-right: 8px;
    width: 62px;
    height: 28px;
`

export const LazyImage = styled(Image).attrs({
    loading: 'lazy',
})`
    display: block;
`

export const SubtitleContent = styled.div`
    font: 400 ${({ theme }) => theme.typography.font.size.xSmall} / 16px
        ${({ theme }) => theme.typography.font.family.inter};
    color: ${({ theme }) => theme.colors.tfwBlack99};
    display: ${props => props.theme.style.display.flex};
    align-items: center;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: 14px;
        line-height: 24px;
    }
`

export const FormTitle = styled(H3).attrs({ as: 'h1' })`
    margin: 16px 0 ${({ theme }) => theme.spacing.xSmall};
    font: 700 ${({ theme }) => theme.typography.font.size.large} / 26px
        ${({ theme }) => theme.typography.font.family.inter};
    color: ${({ theme }) => theme.colors.tfwBlack99};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: 29px;
        line-height: 40px;
    }
`
export const FormSubtitle = styled.div`
    display: ${props => props.theme.style.display.flex};
`

export const RegistrationFormContainer = styled.div`
    margin: ${({ theme }) => theme.spacing.large} 0;
`

export const AdditionalTextContainer = styled(Markdown)`
    font: 400 ${({ theme }) => theme.typography.font.size.xSmall} / 16px
        ${({ theme }) => theme.typography.font.family.inter};
    color: ${props => props.theme.colors.tfwBlack87};

    & p {
        margin: 0;
    }
    & p a {
        color: ${({ theme }) => theme.colors.tfwBlack87};
        font-weight: normal;
        :hover {
            text-decoration: unset;
        }
    }

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: 0;
    }
`
export const RegistrationFormContentContainer = styled.div`
    margin: 2px;
    background: white;
    padding: 8px 24px 24px;
    border-radius: 20px;
`
