import styled from 'styled-components'
import { desktopBreakpoint, tabletBreakpoint, wideScreenBreakpoint } from 'react-components'

import { H3, P } from '@src/components/common/styles'

export const Container = styled.div`
    background-color: ${props => props.theme.colors.white};
    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        padding-top: 5rem;
    }
`

export const Section = styled.div`
    padding: 16px;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        max-width: 1200px;
        margin: auto;
        padding: 0 16px;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        border-top: unset;
        &:first-child {
            padding-bottom: 0;
        }
        &:last-child {
            padding-top: 0;
        }
    }
`

export const Title = styled(H3).attrs({ as: 'h1' })`
    font: 700 25px/30px ${({ theme }) => theme.typography.font.family.inter};
    color: ${({ theme }) => theme.colors.tfwBlack99};
    margin-top: 1rem;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: 32px;
        line-height: 40px;
    }
`

export const Subtitle = styled(P)`
    margin: 8px auto;
    font: 400 ${({ theme }) => theme.typography.font.size.small} / 22px
        ${({ theme }) => theme.typography.font.family.inter};
    color: ${({ theme }) => theme.colors.tfwBlack40};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: 16px;
        line-height: 24px;
        margin: 8px auto 0;
    }
`

export const TitleContainer = styled.div`
    text-align: center;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-bottom: 24px;
    }
`
