import React, { FC, memo, Ref } from 'react'

import {
    Section,
    SectionContent,
    CalendarIcon,
    NoSignIcon,
    MoneyIcon,
    ContentContainer,
    Title,
    ListContainer,
    ListContentContainer,
    ListIconContainer,
    ListTextContainer,
    SliderContainer,
    RegistrationFormContainer,
    ListContent,
    BoldText,
    ImageLoader,
    RatingContainer,
    RatingContentContainer,
    RateContainer,
    TrustPilotContainer,
    RatingInnerContainer,
    ListContentInnerContainer,
} from './styles'
import HeroSlider from './slider'
import { SectionContainer } from '@src/components/home-page-v3/styles'
import HORegistrationForm from '../ho-registration-form'
import ListingInfoForm from './listing-info-form'
import { NotificationMessageState } from '@src/redux/notification-channel'
import { CustomerSupportSectionTransport, HeroSectionTransport } from '@common/transports/home-page-transport'
import HeaderRating from '@src/assets/picture/header-rating.png'
import TrustPilotIcon from '@src/assets/picture/trustpilot.png'
import { useTranslation } from '../../../hooks/locale'
import { HERO_SECTION_MAIN_TEXT, HERO_SECTION_SUBTEXT, HERO_SECTION_ITEMS } from '@server/constants'

interface HeroSectionProps {
    heroSection: HeroSectionTransport
    customerSupport: CustomerSupportSectionTransport
    isLoggedIn: boolean
    sectionRef?: Ref<HTMLElement>
    disposeNotification: (message: NotificationMessageState) => void
    isRegistrationFormVisible?: boolean
    registrationFormRef?: Ref<HTMLDivElement>
    isAdvertisementGlowing: boolean
}

const HeroSection: FC<HeroSectionProps> = ({
    heroSection: { title, listingInfoForm, registerButton, dashboardButton, registrationForm, backgroundImage },
    customerSupport,
    isLoggedIn,
    disposeNotification,
    isRegistrationFormVisible = false,
    sectionRef,
    registrationFormRef,
    isAdvertisementGlowing,
}) => {
    const _listingInfoForm = (
        <ListingInfoForm
            desktopTitle={title}
            listingInfoForm={listingInfoForm}
            isLoggedIn={isLoggedIn}
            registerButton={registerButton}
            dashboardButton={dashboardButton}
            customerSupport={customerSupport}
        />
    )

    const { t } = useTranslation()

    const _getIconComponent = (key: string) => {
        switch (key) {
            case 'booking':
                return <CalendarIcon />
            case 'commission':
                return <NoSignIcon />
            case 'moneyBack':
                return <MoneyIcon />
        }
    }

    return (
        <SectionContainer id="hero-v2" ref={sectionRef}>
            <Section>
                <SectionContent>
                    <ContentContainer>
                        <Title>{t('heroTitle')}</Title>
                        <RatingContainer>
                            <RatingInnerContainer>
                                <RatingContentContainer>{t('heroRatingContent')}</RatingContentContainer>
                                <RateContainer>
                                    <ImageLoader alt={t('heroRatingContentAlt')} src={HeaderRating} />
                                </RateContainer>
                                <TrustPilotContainer>
                                    <ImageLoader alt={t('trustPilotAlt')} src={TrustPilotIcon} />
                                </TrustPilotContainer>
                            </RatingInnerContainer>
                        </RatingContainer>
                        <ListContainer>
                            {HERO_SECTION_ITEMS.map(item => (
                                <ListContentContainer key={item.key}>
                                    <ListContentInnerContainer>
                                        <ListContent>
                                            <ListIconContainer>{_getIconComponent(item.key)}</ListIconContainer>
                                        </ListContent>
                                        <ListContent>
                                            <ListTextContainer>
                                                <BoldText>{t(item.key + HERO_SECTION_MAIN_TEXT)}</BoldText>&nbsp;
                                                {t(item.key + HERO_SECTION_SUBTEXT)}
                                            </ListTextContainer>
                                        </ListContent>
                                    </ListContentInnerContainer>
                                </ListContentContainer>
                            ))}
                        </ListContainer>
                        <SliderContainer>
                            <HeroSlider />
                        </SliderContainer>
                    </ContentContainer>
                    <RegistrationFormContainer ref={registrationFormRef} id="registration-form">
                        {!isRegistrationFormVisible ? (
                            _listingInfoForm
                        ) : (
                            <HORegistrationForm
                                registrationForm={registrationForm}
                                disposeNotification={disposeNotification}
                                backgroundImage={backgroundImage}
                                isAdvertisementGlowing={isAdvertisementGlowing}
                            />
                        )}
                    </RegistrationFormContainer>
                </SectionContent>
            </Section>
        </SectionContainer>
    )
}

export default memo(HeroSection)
