import styled from 'styled-components'
import { desktopBreakpoint, tabletBreakpoint, wideScreenBreakpoint } from 'react-components'

import { H3 } from '@src/components/common/styles'
import { StyledFAQGroup } from '@src/components/home-page-v3/faq-section/faq-group-styles'

export const Section = styled.div`
    padding: 24px 16px;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: 24px 16px 48px;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        padding: 48px 16px;
    }
`

export const SectionContent = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex-direction: column;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        max-width: 1200px;
        margin: 22px auto 0;
    }
`

export const Title = styled(H3).attrs({ as: 'h1' })`
    font: 700 25px/30px ${({ theme }) => theme.typography.font.family.inter};
    color: ${({ theme }) => theme.colors.tfwBlack99};
    text-align: center;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: 32px;
        line-height: 40px;
    }
`

export const Description = styled.div`
    font: 400 ${({ theme }) => theme.typography.font.size.small} / 22px
        ${({ theme }) => theme.typography.font.family.inter};
    color: ${({ theme }) => theme.colors.tfwBlack40};
    text-align: center;
    margin-top: 8px;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: 16px;
        line-height: 24px;
    }
`

export const TitleContainer = styled.div`
    margin: 16px 0 8px;
`

export const SectionContainer = styled.div`
    scroll-margin-top: 60px;
`

export const FAQGroupContainer = styled.ul`
    overflow: auto hidden;
    max-width: 100%;
    white-space: nowrap;
    margin: 8px auto;
    box-shadow: inset 0 -2px 0 ${({ theme }) => theme.colors.tfwBlack12};
    z-index: 1;

    & ${StyledFAQGroup} {
        margin-right: ${({ theme }) => theme.spacing.xSmall};
        :last-child {
            margin-right: 0;
        }

        @media only screen and (min-width: ${tabletBreakpoint.min}px) {
            margin-right: ${({ theme }) => theme.spacing.xLarge};
        }
        @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
            margin-right: ${({ theme }) => theme.spacing.xxLarge};
        }
    }

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-bottom: 1rem;
        box-shadow: unset;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin-bottom: 1.5rem;
    }
`
