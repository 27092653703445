import { object, string } from 'yup'
import { FormTransport } from '../../../../../common/transports/ho-registration-form-transports'

const getRegistrationFormSchema = ({ salutation, firstName, lastName, email, phone }: FormTransport) =>
    object().shape({
        salutation: object().nullable().required(salutation.errorTypes?.required),
        firstName: string()
            .trim()
            .required(firstName.errorTypes?.required)
            .matches(/^[ .A-Za-z\-]+$/, firstName.errorTypes?.valid)
            .min(2, firstName.errorTypes?.belowLowerBound),
        lastName: string()
            .trim()
            .required(lastName.errorTypes?.required)
            .matches(/^[ .A-Za-z\-]+$/, lastName.errorTypes?.valid)
            .min(2, lastName.errorTypes?.belowLowerBound),
        email: string().trim().required(email.errorTypes?.required).email(email.errorTypes?.valid),
        phone: string()
            .trim()
            .required(phone.errorTypes?.required)
            .matches(/\+\d{4,21}/, phone.errorTypes?.valid),
    })

export default getRegistrationFormSchema
