import React, { ChangeEventHandler, FC, memo, MouseEventHandler, useCallback, useState } from 'react'
import { SolidButton } from 'react-components'
import CustomerSupportDetails from './customer-support-detail'
import {
    ActionIconsContainer,
    AddIcon,
    ButtonContainer,
    Container,
    DeleteIcon,
    DesktopTitle,
    IconContainer,
    ListingFormGroup,
    Label,
    Select,
    Input,
    CustomerSupportWrapper,
} from '@src/components/home-page-v3/hero-section/listing-info-form-styles'
import { CustomerSupportSectionTransport, ListingInfoFormTransport } from '@common/transports/home-page-transport'
import { CookieManagerService } from '@common/services'
import ButtonTransport from '@common/transports/common/button-transport'
import { SectionContainer } from '@src/components/home-page-v3/styles'
import { AnalyticsService } from '@src/services'
import { homePageEventCategories } from '@src/services/analytics-service/categories'
import FormInputOptionTransport from '@common/transports/form/form-input-option-transport'
import { getListingTypeLabel } from '@src/components/home-page-v3/hero-section/utils'
import { useEffectExceptOnMount } from '@src/hooks/dom'

interface Props {
    listingInfoForm: ListingInfoFormTransport
    customerSupport: CustomerSupportSectionTransport
    registerButton: ButtonTransport
    dashboardButton: ButtonTransport
    isLoggedIn: boolean
    desktopTitle: string
}

const _getDefaultListingType = (listingTypes?: FormInputOptionTransport<string>[]) =>
    listingTypes?.[9]?.value ?? listingTypes?.[0]?.value

const _maxLengthNumberOfGuests = 2

const ListingInfoForm: FC<Props> = ({
    listingInfoForm,
    registerButton,
    dashboardButton,
    isLoggedIn,
    desktopTitle,
    customerSupport,
}) => {
    const [listingType, setListingType] = useState(_getDefaultListingType(listingInfoForm.listingType.options))

    const [numberOfGuests, setNumberOfGuests] = useState(3)

    const _handleListingTypeChange = useCallback(event => setListingType(event.target.value), [])

    const _handleNumberOfGuestsChange: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
        const numberOfGuests = Number.parseInt(event.target.value)
        if (numberOfGuests >= 0 && numberOfGuests <= Number.MAX_SAFE_INTEGER) {
            setNumberOfGuests(numberOfGuests)
        } else if (event.target.value === '') {
            setNumberOfGuests(0)
        }
    }, [])

    const _handleDecrementButtonClick: MouseEventHandler<HTMLButtonElement> = useCallback(event => {
        event.stopPropagation()
        setNumberOfGuests(numberOfGuests => {
            if (numberOfGuests > 0) {
                return numberOfGuests - 1
            }
            return numberOfGuests
        })
    }, [])

    const _handleIncrementButtonClick: MouseEventHandler<HTMLButtonElement> = useCallback(event => {
        event.stopPropagation()
        setNumberOfGuests(numberOfGuests => {
            if (numberOfGuests < Number.MAX_SAFE_INTEGER) {
                return numberOfGuests + 1
            }
            return numberOfGuests
        })
    }, [])

    const _handleSubmit = useCallback(() => {
        if (registerButton.eventLabel) {
            AnalyticsService.trackClickEvent(homePageEventCategories.HERO_SECTION, registerButton.eventLabel)
        }
        CookieManagerService.setCookie(
            'listing_info_form',
            { numberOfGuests, listingType },
            { domain: CookieManagerService.getCookieDomain() },
        )
    }, [registerButton.eventLabel, listingType, numberOfGuests])

    useEffectExceptOnMount(() => {
        AnalyticsService.trackClickEvent(homePageEventCategories.HERO_SECTION, `suitable for ${numberOfGuests}`)
    }, [numberOfGuests])

    useEffectExceptOnMount(() => {
        if (listingType) {
            const listingTypeLabel = getListingTypeLabel(Number.parseInt(listingType))
            if (listingTypeLabel) {
                AnalyticsService.trackClickEvent(
                    homePageEventCategories.HERO_SECTION,
                    `property type ${listingTypeLabel}`,
                )
            }
        }
    }, [listingType])

    return (
        <SectionContainer id={listingInfoForm.id}>
            <CustomerSupportWrapper>
                <Container>
                    <DesktopTitle>{desktopTitle}</DesktopTitle>
                    <ListingFormGroup>
                        <Label htmlFor="listing-type">{listingInfoForm.listingType.label}</Label>
                        <Select
                            id="listing-type"
                            name="listing-type"
                            value={listingType}
                            onChange={_handleListingTypeChange}
                        >
                            {listingInfoForm.listingType.options?.map(listingTypeOption => (
                                <option key={listingTypeOption.value} value={listingTypeOption.value}>
                                    {listingTypeOption.label}
                                </option>
                            ))}
                        </Select>
                    </ListingFormGroup>

                    <ListingFormGroup>
                        <Label htmlFor="number-of-guests">{listingInfoForm.numberOfGuests.label}</Label>
                        <Input
                            id="number-of-guests"
                            name="number-of-guests"
                            value={numberOfGuests}
                            onChange={_handleNumberOfGuestsChange}
                            maxLength={_maxLengthNumberOfGuests}
                        />
                        <ActionIconsContainer>
                            <IconContainer type="button" onClick={_handleDecrementButtonClick}>
                                <DeleteIcon />
                            </IconContainer>
                            <IconContainer type="button" onClick={_handleIncrementButtonClick}>
                                <AddIcon />
                            </IconContainer>
                        </ActionIconsContainer>
                    </ListingFormGroup>
                    {isLoggedIn ? (
                        <ButtonContainer>
                            <SolidButton href={dashboardButton.url} padding="12px" width="100%">
                                {dashboardButton.text}
                            </SolidButton>
                        </ButtonContainer>
                    ) : (
                        <ButtonContainer>
                            <SolidButton href={registerButton.url} padding="12px" width="100%" onClick={_handleSubmit}>
                                {registerButton.text}
                            </SolidButton>
                        </ButtonContainer>
                    )}
                </Container>
                <CustomerSupportDetails customerSupport={customerSupport} />
            </CustomerSupportWrapper>
        </SectionContainer>
    )
}

export default memo(ListingInfoForm)
