import React, { FC } from 'react'

import FAQItem from './faq-item'
import { FAQItemTransport } from '@common/transports/home-page-transport'
import { Container } from './faqs-styles'

interface Props {
    faqs: FAQItemTransport[]
    shouldShowFaqs: boolean
}

const FAQs: FC<Props> = ({ faqs, shouldShowFaqs }) => (
    <Container shouldShow={shouldShowFaqs}>
        {faqs.map(faq => (
            <FAQItem key={faq.title} {...faq} />
        ))}
    </Container>
)

export default FAQs
