import styled, { StyledComponent } from 'styled-components'
import { desktopBreakpoint, tabletBreakpoint, wideScreenBreakpoint } from 'react-components'

import { P } from '@src/components/common/styles'

export const StyledNavigationItem = styled(P as StyledComponent<'a', any>).attrs({ as: 'a' })`
    text-decoration: none;
    display: ${props => props.theme.style.display.flex};
    align-items: center;
    cursor: pointer;
    font: 600 ${({ theme }) => theme.typography.font.size.small} / 24px
        ${({ theme }) => theme.typography.font.family.inter};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: 8px 6px;
        margin-right: 40px;
        &:hover {
            background-color: #2298da0a;
            border-radius: ${({ theme }) => theme.typography.border.radius.small};
        }
    }
`
