import React, { FC, memo, useRef, useContext, useState } from 'react'
import FairnessPrincipleModal from './fairness-principle-modal'
import { useTranslation } from '@src/hooks/locale'
import { BENEFITS_SECTION_ITEMS } from '@server/constants'

import {
    BenefitContainer,
    Container,
    Description,
    IconContainer,
    Title,
    BenefitContentContainer,
    CoupleIcon,
    ShieldIcon,
    MoneyIcon,
    HelpdeskIcon,
    DashboardIcon,
    EffectsIcon,
    InnerContainer,
    ModalContainer,
} from './benefits-styles'

import { Modal, ScreenContext } from 'react-components'
import MoneyBackGuaranteeModal from './money-back-guarantee-modal'

import {
    FairnessPrincipleModalTransport,
    MoneyBackGuaranteeModalTransport,
} from '@common/transports/home-page-transport'

interface Props {
    fairnessPrincipleModal: FairnessPrincipleModalTransport
    moneyBackGuaranteeModal: MoneyBackGuaranteeModalTransport
}

const Benefits: FC<Props> = ({ fairnessPrincipleModal, moneyBackGuaranteeModal }) => {
    const viewMoreButtonRef = useRef<HTMLAnchorElement>(null)
    const { isMobile, isWideScreen } = useContext(ScreenContext)
    const [isOpen, setIsOpen] = useState(false)
    const [modalName, setModalName] = useState('')

    const onClick = (item: string) => {
        setModalName(item)
        setIsOpen(!isOpen)
    }

    const onClickClose = () => {
        setIsOpen(!isOpen)
    }

    const { t } = useTranslation()

    const getIconComponent = (iconName: string) => {
        switch (iconName) {
            case 'guestBenefit':
                return <CoupleIcon />
            case 'fairnessBenefit':
                return <ShieldIcon />
            case 'refundBenefit':
                return <MoneyIcon />
            case 'supportBenefit':
                return <HelpdeskIcon />
            case 'successBenefit':
                return <DashboardIcon />
            case 'adsBenefit':
                return <EffectsIcon />
        }
    }

    return (
        <Container>
            {BENEFITS_SECTION_ITEMS.map(item => (
                <BenefitContainer key={item.key}>
                    <InnerContainer>
                        <BenefitContentContainer>
                            <IconContainer>{getIconComponent(item.key)}</IconContainer>
                        </BenefitContentContainer>
                        <BenefitContentContainer>
                            <Title>{t(item.key + 'Title')}</Title>
                            <Description>
                                {t(item.key + 'Description')}
                                {item.modal && (
                                    <ModalContainer
                                        ref={viewMoreButtonRef}
                                        role="button"
                                        onClick={() => onClick(item.key)}
                                    >
                                        {t('benefitSectionMore')}
                                    </ModalContainer>
                                )}
                            </Description>
                        </BenefitContentContainer>
                    </InnerContainer>
                </BenefitContainer>
            ))}
            <Modal
                isOpen={isOpen}
                onRequestClose={onClickClose}
                style={{
                    content: {
                        margin: isWideScreen || isMobile ? '0' : '0 5.5625rem',
                        maxWidth: isMobile ? '100%' : '52.875rem',
                        maxHeight: isMobile ? '100%' : '98%',
                        borderRadius: isMobile ? '0' : '1rem',
                        height: isMobile ? '100%' : 'auto',
                    },
                    overlay: {
                        zIndex: 1003,
                    },
                }}
            >
                {modalName === 'fairnessBenefit' ? (
                    <FairnessPrincipleModal modalSection={fairnessPrincipleModal} onClick={onClickClose} />
                ) : (
                    <MoneyBackGuaranteeModal modalSection={moneyBackGuaranteeModal} onClick={onClickClose} />
                )}
            </Modal>
        </Container>
    )
}

export default memo(Benefits)
