import styled from 'styled-components'
import { tabletBreakpoint } from 'react-components'

export const ButtonContainer = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 0;
    cursor: pointer;
    padding: 0;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: ${props => props.theme.spacing.xSmall};
    }
`
