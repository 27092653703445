import React, { FC, Fragment, memo, useContext, useMemo } from 'react'
import { Slide } from 'pure-react-carousel'
import { ScreenContext } from 'react-components'

import { TestimonialsSectionTransport, TestimonialTransport } from '@common/transports/home-page-transport'
import TestimonialCard from '@src/components/home-page-v3/testimonials-section/testimonial-card'
import CarouselDots from './carousel-dots'
import CarouselBackButton from './carousel-back-button'
import CarouselNextButton from './carousel-next-button'
import {
    SectionContainer,
    Container,
    DotContainer,
    StyledCarouselProvider,
    SliderContainer,
    StyledSlider,
    Subtitle,
    Title,
    TitleContainer,
} from '@src/components/home-page-v3/testimonials-section/styles'
import { basicEventActions } from '@src/services/analytics-service/actions'
import TrackSlideChange from '@src/components/home-page-v3/testimonials-section/track-slide-change'

interface Props {
    testimonialsSection: TestimonialsSectionTransport
}

const _renderSlider = (testimonials: TestimonialTransport[], associatedSince: string) => (
    <StyledSlider>
        {testimonials.map((testimonial, index) => (
            <Slide key={testimonial.givenBy} index={index}>
                <TestimonialCard testimonial={testimonial} associatedSince={associatedSince} />
            </Slide>
        ))}
    </StyledSlider>
)

const TestimonialsSection: FC<Props> = ({
    testimonialsSection: { id, title, subtitle, testimonials, associatedSince },
}) => {
    const _upperLimit = Math.floor((testimonials.length + 1) / 2)
    const _slider = useMemo(() => _renderSlider(testimonials, associatedSince), [associatedSince, testimonials])
    const { isMobile, isTablet, isDesktop } = useContext(ScreenContext)

    const _visibleSlideRatio = isMobile ? 1 : isTablet ? 2.2 : 2
    const _steps = isDesktop ? 2 : 1

    return (
        <SectionContainer id={id}>
            <Container>
                <TitleContainer>
                    <Title>{title}</Title>
                    <Subtitle>{subtitle}</Subtitle>
                </TitleContainer>

                <StyledCarouselProvider
                    totalSlides={testimonials.length}
                    visibleSlides={_visibleSlideRatio}
                    naturalSlideWidth={1}
                    naturalSlideHeight={1}
                    isIntrinsicHeight
                    step={_steps}
                >
                    {isDesktop ? (
                        <Fragment>
                            <SliderContainer>
                                <CarouselBackButton />
                                {_slider}
                                <CarouselNextButton numberOfSlides={testimonials.length} />
                            </SliderContainer>
                            <DotContainer>
                                <CarouselDots numberOfDots={_upperLimit} />
                            </DotContainer>
                        </Fragment>
                    ) : (
                        _slider
                    )}
                    <TrackSlideChange eventAction={isDesktop ? basicEventActions.CLICK : basicEventActions.SWIPE} />
                </StyledCarouselProvider>
            </Container>
        </SectionContainer>
    )
}

export default memo(TestimonialsSection)
