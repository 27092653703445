import styled, { AnyStyledComponent } from 'styled-components'
import { desktopBreakpoint, tabletBreakpoint, SolidButton, wideScreenBreakpoint } from 'react-components'

import { CustomMarkdown, H5, P } from '@src/components/common/styles'

import MLW1Logo from '../../../assets/images/globe.svg'
import MLW2Logo from '../../../assets/images/payments.svg'
import MLW3Logo from '../../../assets/images/format-color-text.svg'
import MLW4Logo from '../../../assets/images/crowdsource.svg'
import MLW5Logo from '../../../assets/images/offline-bolt.svg'

export const Container = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex-direction: column;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-top: 1rem;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin-top: 0;
    }
    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        margin-top: 1rem;
    }
`

export const BenefitContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    justify-content: center;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        justify-content: left;
        margin-bottom: 46px;
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        justify-content: left;
        padding: 16px 0 8px;
        margin-bottom: 8px;
    }
`

export const OuterIconContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    align-items: center;
`

export const IconContainer = styled.div`
    display: inline-block;
    height: 32px;
    width: 32px;
    text-align: center;
    margin-right: 24px;
`

export const Title = styled(H5).attrs({ as: 'h2' })`
    margin-bottom: ${props => props.theme.spacing.xxSmall};
    font: 700 ${({ theme }) => theme.typography.font.size.large} / 26px
        ${({ theme }) => theme.typography.font.family.inter};
    color: ${({ theme }) => theme.colors.tfwBlack99};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: 20px;
        margin-bottom: ${({ theme }) => theme.spacing.xxSmall};
    }
`

export const Description = styled(CustomMarkdown)`
    font: 400 ${({ theme }) => theme.typography.font.size.small} / 22px
        ${({ theme }) => theme.typography.font.family.inter};
    color: ${({ theme }) => theme.colors.tfwBlack40};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding-right: 4px;
    }
`

export const BenefitSection = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex-direction: column;

    padding: 16px 0 8px;
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        padding: 0;
    }
`

export const MLW1Icon = styled(MLW1Logo as AnyStyledComponent)`
    width: 100%;
    height: 100%;
`

export const MLW2Icon = styled(MLW2Logo as AnyStyledComponent)`
    width: 100%;
    height: 100%;
`

export const MLW3Icon = styled(MLW3Logo as AnyStyledComponent)`
    width: 100%;
    height: 100%;
`

export const MLW4Icon = styled(MLW4Logo as AnyStyledComponent)`
    width: 100%;
    height: 100%;
`

export const MLW5Icon = styled(MLW5Logo as AnyStyledComponent)`
    width: 100%;
    height: 100%;
`

export const OuterButtonContainer = styled.div`
    margin-top: 8px;
`

export const ButtonContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    text-align: center;
    width: 100%;
    padding: 16px 0;
    margin: auto;
`

export const RegisterButton = styled(SolidButton)`
    padding: 0;
    width: ${({ theme }) => theme.layout.fill};
    background-color: #f1aa10;
`

export const ButtonTextContainer = styled(P)`
    padding: 12px;
    font: 700 ${({ theme }) => theme.typography.font.size.medium} / 24px
        ${({ theme }) => theme.typography.font.family.inter};
`
