import { registrationFormEventLabels } from '../labels'

const getRegistrationEventLabels = (fieldName: string, value: any) => {
    switch (fieldName) {
        case 'salutation':
            return registrationFormEventLabels.fieldError.SALUTATION_EMPTY
        case 'firstName':
            return value
                ? registrationFormEventLabels.fieldError.FIRST_NAME_INVALID
                : registrationFormEventLabels.fieldError.FIRST_NAME_EMPTY
        case 'lastName':
            return value
                ? registrationFormEventLabels.fieldError.LAST_NAME_INVALID
                : registrationFormEventLabels.fieldError.LAST_NAME_EMPTY
        case 'email':
            return value ? null : registrationFormEventLabels.fieldError.EMAIL_EMPTY
        case 'phone':
            return value
                ? registrationFormEventLabels.fieldError.PHONE_NUMBER_INVALID
                : registrationFormEventLabels.fieldError.PHONE_NUMBER_EMPTY
        default:
            return null
    }
}

export default getRegistrationEventLabels
