import styled from 'styled-components'
import { tabletBreakpoint } from 'react-components'

export const ContentContainer = styled.div`
    background-color: #f5f5f5;
    padding-bottom: 50px;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding-bottom: 0;
    }
`

export const ImageContainer = styled.div`
    padding: 16px 0 24px;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: 0;
        display: ${props => props.theme.style.display.flex};
    }
`

export const Image = styled.img`
    width: 136.12px;
    height: 48px;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        height: 2.5rem;
        width: 7.6875rem;
    }
`

export const Container = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex-direction: column;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        flex-direction: row;
        align-items: center;
    }
`

export const LinksContainer = styled.div`
    padding: 0 ${({ theme }) => theme.spacing.medium};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        display: ${props => props.theme.style.display.flex};
        padding: 0;
    }
`

export const InnerContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex-direction: column;
    text-align: center;
    margin: auto;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        max-width: 1200px;
        padding: ${({ theme }) => `${theme.spacing.medium} ${theme.spacing.large}`};
        text-align: left;
    }
`
