import React, { FC, memo, Fragment } from 'react'
import { Circle, theme } from 'react-components'
import { useCurrentSlideIndex } from '@src/hooks/carousel'

import { StyledButtonNext, RightArrowIcon } from './styles'
import { AnalyticsService } from '@src/services'
import { homePageEventCategories } from '@src/services/analytics-service/categories'
import { homePageEventLabels } from '@src/services/analytics-service/labels'

interface Props {
    numberOfSlides: number
}

const _handleNextSlideClick = () => {
    AnalyticsService.trackClickEvent(
        homePageEventCategories.TESTIMONIALS_SECTION,
        homePageEventLabels.testimonialsSection.CHANGE_CAROUSEL_SLIDE,
    )
}

const CarouselNextButton: FC<Props> = ({ numberOfSlides }) => {
    const _currentSlideIndex = useCurrentSlideIndex()

    return _currentSlideIndex < numberOfSlides - 2 ? (
        <StyledButtonNext onClick={_handleNextSlideClick}>
            <Circle radius={theme.typography.border.radius.xxxLarge} fillColor={theme.colors.white}>
                <RightArrowIcon />
            </Circle>
        </StyledButtonNext>
    ) : (
        <Fragment />
    )
}

export default memo(CarouselNextButton)
