import React, { memo, useContext, useCallback } from 'react'
import { ThemeContext } from 'styled-components'

import PhoneIcon from '../../../assets/images/phone-outgoing.svg'
import EmailIcon from '../../../assets/images/mail.svg'
import {
    SectionContainer,
    BannerArea,
    Title,
    Description,
    ContactArea,
    IconContainer,
    CSupportContainer,
    EmailRow,
    PhoneRow,
    CustomerSupportWrapper,
    ButtonContainer,
    ButtonContainerText,
    ContactTitle,
    ContactDescription,
    TitleContainer,
    ButtonInnerContainer,
    ContactContainer,
    ContactContentContainer,
    RegisterButton,
} from './styles'
import CustomerSupportButton from '@src/components/home-page-v3/customer-support-section/customer-support-button'
import { CustomerSupportSectionTransport } from '@common/transports/home-page-transport'
import { AnalyticsService } from '@src/services'
import { homePageEventCategories } from '@src/services/analytics-service/categories'
import { homePageEventLabels } from '@src/services/analytics-service/labels'
import { useTranslation } from '@src/hooks/locale'

interface Props {
    customerSupportSection: CustomerSupportSectionTransport
}

const CustomerSupportSection: React.FC<Props> = ({ customerSupportSection: { title, description, phone, email } }) => {
    const theme = useContext(ThemeContext)

    const _handleButtonClick = useCallback(() => {
        AnalyticsService.trackClickEvent(
            homePageEventCategories.CANARY_HO,
            homePageEventLabels.customerSupportSection.BUTTON,
        )
    }, [])

    const { t } = useTranslation()

    return (
        <SectionContainer>
            <CustomerSupportWrapper>
                <CSupportContainer>
                    <BannerArea>
                        <TitleContainer>
                            <Title>{title}</Title>
                            <Description inputText={t('contactSubtitle')} escapeHtml />
                        </TitleContainer>
                        <ButtonContainer>
                            <ButtonInnerContainer>
                                <RegisterButton href="#registration-form" onClick={_handleButtonClick}>
                                    <ButtonContainerText> {t('customerSupportButton')}</ButtonContainerText>
                                </RegisterButton>
                            </ButtonInnerContainer>
                        </ButtonContainer>
                    </BannerArea>
                    <ContactArea>
                        <ContactContainer>
                            <ContactContentContainer>
                                <ContactTitle> {t('contactTitle')}</ContactTitle>
                                <ContactDescription>{t('contactDescription')}</ContactDescription>
                            </ContactContentContainer>
                        </ContactContainer>
                        <PhoneRow>
                            <IconContainer>
                                <PhoneIcon />
                            </IconContainer>
                            <CustomerSupportButton item={phone} />
                        </PhoneRow>
                        <EmailRow>
                            <IconContainer>
                                <EmailIcon />
                            </IconContainer>
                            <CustomerSupportButton item={email} />
                        </EmailRow>
                    </ContactArea>
                </CSupportContainer>
            </CustomerSupportWrapper>
        </SectionContainer>
    )
}

export default memo(CustomerSupportSection)
