import styled from 'styled-components'
import { desktopBreakpoint, tabletBreakpoint, wideScreenBreakpoint } from 'react-components'
import { CustomMarkdown } from '@src/components/common/styles'

export const AdditionalPoliciesContainer = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    justify-content: ${({ theme }) => theme.style.justifyContent.spaceBetween};
    max-width: 1200px;
    width: 100%;
    height: auto;
    margin: 0;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        flex-direction: ${({ theme }) => theme.style.flexDirection.column};
        text-align: ${({ theme }) => theme.style.textAlign.left};
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        flex-direction: ${({ theme }) => theme.style.flexDirection.row};
    }
`

export const AdditionalPoliciesSectionContainer = styled.div`
    background-color: ${props => props.theme.colors.white};
    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        padding-top: 5rem;
    }
`

export const AdditionalPolicySection = styled.div`
    padding: 3.125rem ${({ theme }) => theme.spacing.medium} 0rem ${({ theme }) => theme.spacing.medium};
    border-top: ${({ theme }) => `${theme.colors.tfwWhite32} solid ${theme.typography.border.width.xxxThin}`};

    &:first-child {
        border-top: unset;
    }

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: 2.4375rem ${({ theme }) => theme.spacing.large} 1.875rem ${({ theme }) => theme.spacing.large};
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        padding: 3.75rem ${({ theme }) => theme.spacing.large};
        margin: 3.75rem 0 0;
        border-top: unset;
        &:first-child {
            padding-bottom: 0;
        }
        &:last-child {
            padding-top: 0;
        }
    }
    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        padding: 0 8.375rem;
        margin-top: 1.25rem;
        margin-bottom: 0.125rem;
    }
`

export const AdditionalPoliciesSectionContent = styled.div`
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        text-align: center;
        max-width: 1200px;
        margin: 0 auto;
    }
`

export const MoneyBackContainer = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    width: 100%;
    height: auto;
    border: ${({ theme }) => theme.typography.border.width.xxxThin}
        ${({ theme }) => theme.typography.border.style.solid} #fdc400;
    border-radius: ${({ theme }) => theme.typography.border.radius.xLarge};
    margin: 0;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        flex-direction: ${({ theme }) => theme.style.flexDirection.row};
        text-align: ${({ theme }) => theme.style.textAlign.left};
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin-right: 1rem;
    }
    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        margin-right: 1.5rem;
    }
`

export const FairnessPrincipleContainer = styled(MoneyBackContainer)`
    border: ${({ theme }) => theme.typography.border.width.xxxThin}
        ${({ theme }) => theme.typography.border.style.solid} #e45f75cc;
    margin: 1rem 0 0;
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin: 0;
        margin-right: 1rem;
    }
    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        margin-right: 1.5rem;
    }
`

export const ImageContainer = styled.div`
    width: 2.875rem;
    height: 2.875rem;
    display: ${({ theme }) => theme.style.display.flex};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
    align-items: ${({ theme }) => theme.style.alignItems.center};
    margin: 1.06375rem 0 0 1rem;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: 1.5625rem 0 0 1.5rem;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin: 1.3125rem 0 0 1.25rem;
    }
    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        margin: 1.5625rem 0 0 1.5rem;
    }
`

export const MoneyBackImage = styled.img`
    display: ${({ theme }) => theme.style.display.flex};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
    align-items: ${({ theme }) => theme.style.alignItems.center};
    width: 2.311rem;
    height: 2.875rem;
`

export const FairnessPrincipleImage = styled(MoneyBackImage)`
    width: 2.875rem;
    height: 2.77rem;
`

export const TextContainer = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    margin: ${({ theme }) => theme.spacing.medium} ${({ theme }) => theme.spacing.medium};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: ${({ theme }) => theme.spacing.medium} 1rem ${({ theme }) => theme.spacing.medium} 1.25rem;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin: ${({ theme }) => theme.spacing.medium} 1rem ${({ theme }) => theme.spacing.medium}
            ${({ theme }) => theme.spacing.medium};
    }
    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        margin: ${({ theme }) => theme.spacing.medium} 1rem ${({ theme }) => theme.spacing.medium} 1.25rem;
    }
`

export const SchemeTitle = styled.h5`
    font-family: ${props => props.theme.typography.font.family.yummo};
    font-weight: ${props => props.theme.typography.font.yummo.weight.bold};
    font-size: ${props => props.theme.typography.font.size.xLarge};
    line-height: 2rem;
    letter-spacing: -0.0175rem;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: 0 ${props => props.theme.spacing.small} 0 0;
    }
`

export const SchemeDescription = styled(CustomMarkdown)`
    font-family: ${props => props.theme.typography.font.family.lato};
    font-style: normal;
    font-weight: ${props => props.theme.typography.font.lato.weight.regular};
    font-size: ${props => props.theme.typography.font.size.medium};
    line-height: 1.5rem;

    margin: ${props => props.theme.spacing.small} 0 0;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: ${props => props.theme.spacing.xxSmall} 0 0;
    }
`

export const LinkToTermsAndConditions = styled.a`
    font-family: ${props => props.theme.typography.font.family.lato};
    font-style: normal;
    font-weight: ${props => props.theme.typography.font.lato.weight.bold};
    font-size: ${props => props.theme.typography.font.size.small};
    line-height: 1.5rem;
    font-feature-settings: 'liga' off;
    color: ${props => props.theme.colors.tfwBlue12};
    margin: ${props => props.theme.spacing.xSmall} 0 0;
    cursor: pointer;
    width: 4.4rem;
    white-space: nowrap;
`

export const StyledListingCriteria = styled.div`
    margin: 0 0 ${({ theme }) => theme.spacing.small};
`
