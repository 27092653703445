import React, { FC, memo, useCallback, useState } from 'react'

import { FAQSectionTransport } from '@common/transports/home-page-transport'
import FAQGroup from './faq-group'
import FAQs from './faqs'
import {
    SectionContainer,
    FAQGroupContainer,
    Section,
    SectionContent,
    Title,
    Description,
    TitleContainer,
} from './styles'
import { useTranslation } from '@src/hooks/locale'

interface Props {
    faqSection: FAQSectionTransport
}

const FAQSection: FC<Props> = ({ faqSection: { id, title, groups } }) => {
    const [selectedGroup, setSelectedGroup] = useState(0)

    const _handleGroupClick = useCallback((index: number) => setSelectedGroup(index), [])
    const { t } = useTranslation()

    return (
        <SectionContainer id={id}>
            <Section>
                <SectionContent>
                    <TitleContainer>
                        <Title>{title}</Title>
                        <Description>{t('faqDescription')}</Description>
                    </TitleContainer>
                    <FAQGroupContainer>
                        {groups.map((item, index) => (
                            <FAQGroup
                                key={item.label}
                                isSelected={selectedGroup === index}
                                currentGroup={index}
                                faqGroup={item}
                                onClick={_handleGroupClick}
                            />
                        ))}
                    </FAQGroupContainer>
                    {groups.map((group, index) => (
                        <FAQs key={group.label} faqs={group.items} shouldShowFaqs={selectedGroup === index} />
                    ))}
                </SectionContent>
            </Section>
        </SectionContainer>
    )
}

export default memo(FAQSection)
