import styled from 'styled-components'

export const CustomerSupportButtonLink = styled.a`
    font: 400 ${({ theme }) => theme.typography.font.size.medium} / 1.5rem
        ${({ theme }) => theme.typography.font.family.inter};
    text-decoration: none;
    color: #282828;
    white-space: initial;
    &:hover {
        text-decoration: underline;
    }
`

export const ButtonContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex-direction: row;
    text-decoration: none;
    color: #000000;
    white-space: nowrap;
`
