import getRegistrationEventLabels from './get-registration-event-labels'
import { registrationFormEventLabels } from '../labels'

const getRegistrationEmailEventLabels = (
    value: any,
    isHomeowner?: boolean,
    isGuest?: boolean,
    isAccountDeletionInProgress?: boolean,
) => {
    if (isAccountDeletionInProgress) {
        return registrationFormEventLabels.fieldError.EMAIL_UNDER_DELETION
    }
    if (isHomeowner) {
        return registrationFormEventLabels.fieldError.EMAIL_EXISTING_HOMEOWNER
    }
    if (isGuest) {
        return registrationFormEventLabels.fieldError.EMAIL_EXISTING_GUEST
    }
    return getRegistrationEventLabels('email', value)
}

export default getRegistrationEmailEventLabels
