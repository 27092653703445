import React, { FC, Fragment, memo, useCallback, useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { SolidButton } from 'react-components'

import { HowItWorksStepTransport } from '@common/transports/home-page-transport'
import HowItWorksStep from './how-it-works-step'
import { StepsContainer, ButtonContainer, ButtonTextContainer } from './how-it-works-steps-styles'
import ButtonTransport from '@common/transports/common/button-transport'
import { AnalyticsService } from '@src/services'
import { homePageEventCategories } from '@src/services/analytics-service/categories'
import { homePageEventLabels } from '@src/services/analytics-service/labels'

interface Props {
    steps: HowItWorksStepTransport[]
    isLoggedIn: boolean
    registerButton?: ButtonTransport
    dashboardButton?: ButtonTransport
    isRegistrationFormVisible: boolean
}

const HowItWorksSteps: FC<Props> = ({
    steps,
    isLoggedIn,
    registerButton,
    dashboardButton,
    isRegistrationFormVisible,
}) => {
    const theme = useContext(ThemeContext)

    const button = isLoggedIn ? dashboardButton : registerButton

    const _handleButtonClick = useCallback(() => {
        AnalyticsService.trackClickEvent(
            homePageEventCategories.CANARY_HO,
            homePageEventLabels.howItWorksV2Section.BUTTON,
        )
    }, [])

    return (
        <Fragment>
            <StepsContainer>
                {steps.map((step, index) => (
                    <HowItWorksStep
                        key={step.title}
                        step={step}
                        stepNumber={index + 1}
                        isFirstStep={index === 0}
                        isLastStep={index === steps.length - 1}
                    />
                ))}
            </StepsContainer>
            {!!button && (
                <ButtonContainer>
                    <SolidButton
                        href="#registration-form"
                        padding="0"
                        width={theme.layout.fill}
                        onClick={_handleButtonClick}
                        backgroundColor="#F1AA10"
                    >
                        <ButtonTextContainer>{button.text}</ButtonTextContainer>
                    </SolidButton>
                </ButtonContainer>
            )}
        </Fragment>
    )
}

export default memo(HowItWorksSteps)
