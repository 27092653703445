import React, { FC, memo, useContext, useState } from 'react'
import { Card, ScreenContext, StarRating } from 'react-components'
import differenceInYears from 'date-fns/differenceInYears'

import { TestimonialTransport } from '@common/transports/home-page-transport'
import {
    CardContent,
    PictureSection,
    PictureContainer,
    RatingReviewContainer,
    EmptyStarIcon,
    FullStarIcon,
    HalfStarIcon,
    RatingContainer,
    ReviewText,
    Subtitle,
    TestimonialImage,
    Title,
    TitleContainer,
    Container,
    ReadMoreLink,
} from './testimonial-card-styles'

interface Props {
    testimonial: TestimonialTransport
    associatedSince: string
}

const _currentDate = new Date()

const TestimonialCard: FC<Props> = ({
    testimonial: { givenBy, image, reviewText, overallRating, partnerSince },
    associatedSince,
}) => {
    const { isDesktop, isMobile } = useContext(ScreenContext)

    const [isExpanded, setIsExpanded] = useState(false)

    const handleReadMoreToggle = () => {
        setIsExpanded(!isExpanded)
    }

    const truncatedText = reviewText.length > 250 ? `${reviewText.slice(0, 250)}...` : reviewText

    return (
        <Card cardHeight="100%" backgroundColor="#f5f5f5" borderRadius="16px">
            <CardContent>
                <Container>
                    <PictureSection>
                        <PictureContainer>
                            <TestimonialImage
                                src={
                                    (!isDesktop && image.formats?.medium?.url) ||
                                    (isDesktop && image.formats?.thumbnail?.url) ||
                                    image.url
                                }
                                alt={image.alt}
                                hasMasterSpinner={false}
                                width={isDesktop ? 200 : 268}
                                height={isDesktop ? 150 : 177}
                            />
                        </PictureContainer>
                    </PictureSection>
                    <RatingReviewContainer>
                        <RatingContainer>
                            <StarRating
                                actualRating={overallRating.actualRating}
                                maxRating={overallRating.maxRating}
                                customFullStar={FullStarIcon}
                                customEmptyStar={EmptyStarIcon}
                                customHalfStar={HalfStarIcon}
                                showNumericRating={false}
                                shouldCenterAlignIcons
                            />
                        </RatingContainer>
                        <ReviewText>
                            {isMobile ? (isExpanded ? reviewText : truncatedText) : reviewText}
                            {reviewText.length > 250 && isMobile && (
                                <ReadMoreLink onClick={handleReadMoreToggle}>
                                    {isExpanded ? 'Read Less' : 'Read More'}
                                </ReadMoreLink>
                            )}
                        </ReviewText>
                    </RatingReviewContainer>
                </Container>
                <TitleContainer>
                    <Title>{givenBy}</Title>
                    <Subtitle>
                        {associatedSince.replace(
                            '{partnerSince}',
                            `${differenceInYears(_currentDate, new Date(partnerSince, 0, 1))}`,
                        )}
                    </Subtitle>
                </TitleContainer>
            </CardContent>
        </Card>
    )
}

export default memo(TestimonialCard)
