import React, { FC, memo } from 'react'
import { SectionContainer } from '@src/components/home-page-v3/styles'
import { ComparisonTableTransport } from '@common/transports/home-page-transport'
import { Container, TitleContainer, Title, Subtitle, ComparisonImageContainer, Image } from './styles'
import { useTranslation } from '@src/hooks/locale'

interface Props {
    comparisonTableSection: ComparisonTableTransport
}

const ComparisonTableSection: FC<Props> = ({ comparisonTableSection: { id, image } }) => {
    const { t } = useTranslation()

    return (
        <SectionContainer id={id}>
            <Container>
                <TitleContainer>
                    <Title>{t('comparisonTableTitle')}</Title>
                    <Subtitle>{t('comparisonTableDescription')}</Subtitle>
                </TitleContainer>
                <ComparisonImageContainer>
                    <Image src={image.desktop.url} alt={t('comparisonTableImageAlt')}></Image>
                </ComparisonImageContainer>
            </Container>
        </SectionContainer>
    )
}

export default memo(ComparisonTableSection)
