import React, { FC, Fragment, memo, useContext } from 'react'
import { ScreenContext } from 'react-components'

import { Section, RentingPlatformContainer, SectionContent } from './styles'
import { SectionContainer } from '@src/components/home-page-v3/styles'
import PremiumPartnershipContainer from './premium-partnership'
import PlatformContainer from './platform'
import PlatformSliderContainer from './platform-slider'

const PremiumPartnershipSection: FC = () => {
    const { isMobile } = useContext(ScreenContext)

    return (
        <SectionContainer id="premium-partnership">
            <Section>
                <SectionContent>
                    <PremiumPartnershipContainer />
                </SectionContent>
                <RentingPlatformContainer>
                    {isMobile ? <PlatformSliderContainer /> : <PlatformContainer />}
                </RentingPlatformContainer>
            </Section>
        </SectionContainer>
    )
}

export default memo(PremiumPartnershipSection)
