import styled from 'styled-components'
import { tabletBreakpoint } from 'react-components'

export const HowItWorksStepContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: 2px auto 16px;
        flex-direction: column;

        &:first-child {
            margin: 2px auto 16px;
        }

        &:last-child {
            margin: 2px auto 16px;
        }
    }
`

export const NumberedDotContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex-direction: column;
    align-items: center;
    margin: 16px 16px 0 0;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        justify-content: center;
        margin: ${({ theme }) => theme.spacing.medium} ${({ theme }) => theme.spacing.medium} 0;
        position: relative;
    }
`

export const HowItWorksStepCardContainer = styled.div`
    margin: 16px 0 8px;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-top: 16px;
    }
`
