import styled, { AnyStyledComponent } from 'styled-components'
import { desktopBreakpoint, tabletBreakpoint, wideScreenBreakpoint } from 'react-components'

import Delete from '../../../assets/images/minus-sign.svg'
import Add from '../../../assets/images/plus-sign.svg'
import { H4, P } from '@src/components/common/styles'

export const CustomerSupportWrapper = styled.div`
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        border-radius: ${({ theme }) => theme.typography.border.radius.small};
        box-shadow: 0px 6px 10px #0000000f, 0px 1px 18px #0000000a, 0px 3px 5px #00000014;
        background: ${({ theme }) => theme.colors.white};
    }
`

export const Container = styled.div`
    padding: ${({ theme }) => `${theme.spacing.large} ${theme.spacing.medium} 50px`};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: ${({ theme }) => `${theme.spacing.large} ${theme.spacing.large} 50px`};
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        display: inline-block;
        max-width: 25rem;
        padding: ${({ theme }) => theme.spacing.xLarge};
    }
`
export const ListingFormGroup = styled.form`
    position: relative;
    margin-bottom: ${({ theme }) => theme.spacing.large};
`
export const Label = styled.label`
    position: absolute;
    transform: translate(14px, -6px) scale(0.75);
    background: ${({ theme }) => theme.colors.white};
    padding: 0 5px;
    font: ${({ theme }) => `500 0.9rem / 1rem ${theme.typography.font.family.lato}`};
    letter-spacing: 0.2px;
    color: #00000080;
    transform-origin: top left;
`
export const Select = styled(P).attrs({ as: 'select' })`
    display: block;
    margin: 0;
    border: ${({ theme }) => theme.typography.border.width.xxxThin} solid #0000001e;
    border-radius: ${({ theme }) => theme.typography.border.radius.small};
    width: 100%;
    padding: ${({ theme }) => `${theme.spacing.small} 40px ${theme.spacing.small} ${theme.spacing.small}`};
    letter-spacing: 0.00934rem;

    appearance: none;
    background: url(${require(`../../../assets/images/dropdown-icon.svg`)}) no-repeat;
    background-position: calc(100% - 8px);

    &:focus {
        outline: none;
        background-color: transparent;
        cursor: pointer;
    }
`

export const Input = styled(P).attrs({ as: 'input' })`
    display: block;
    margin: 0;
    border: ${({ theme }) => theme.typography.border.width.xxxThin} solid #0000001e;
    border-radius: ${({ theme }) => theme.typography.border.radius.small};
    height: unset;
    width: 100%;
    padding: ${({ theme }) => `${theme.spacing.small} 92px ${theme.spacing.small} ${theme.spacing.small}`};

    &:focus {
        outline: none;
    }
`

export const ActionIconsContainer = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
`

export const IconContainer = styled.button`
    height: 100%;
    text-align: center;
    display: inline-block;
    padding: 15px;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
`

export const AddIcon = styled(Add as AnyStyledComponent)`
    vertical-align: middle;
`

export const DeleteIcon = styled(Delete as AnyStyledComponent)`
    vertical-align: middle;
`

export const ButtonContainer = styled.div`
    color: ${props => props.theme.colors.tfwBlack87};
    font: ${({ theme }) => `bold ${theme.typography.font.size.small} / 1.5rem ${theme.typography.font.family.lato}`};
    text-align: ${props => props.theme.style.textAlign.center};
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin: -0.5rem 0;
    }
`

export const DesktopTitle = styled(H4).attrs({ as: 'p' })`
    display: none;
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        display: block;
        margin-bottom: ${({ theme }) => theme.spacing.xLarge};
    }
    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        margin-bottom: ${({ theme }) => theme.spacing.large};
    }
`
