import styled from 'styled-components'
import { desktopBreakpoint, tabletBreakpoint, wideScreenBreakpoint } from 'react-components'

import { H3, P } from '@src/components/common/styles'

export const Section = styled.div`
    padding: 16px;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: 32px 16px;
    }
    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        padding: 48px 16px;
    }
`

export const SectionContent = styled.div`
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        max-width: 1200px;
        margin: 0 auto;
        width: 100%;
        display: ${props => props.theme.style.display.flex};
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
`

export const Title = styled(H3).attrs({ as: 'h1' })`
    font: 700 25px/30px ${({ theme }) => theme.typography.font.family.inter};
    color: ${({ theme }) => theme.colors.tfwBlack99};
    text-align: center;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: 32px;
        line-height: 40px;
    }
`

export const TitleContent = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex-direction: column;
    margin: 16px 0 0;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: 0;
    }
`

export const SectionContainer = styled.div`
    scroll-margin-top: 170px;
`

export const Description = styled(P)`
    margin-top: 8px;
    font: 400 ${({ theme }) => theme.typography.font.size.small} / 22px
        ${({ theme }) => theme.typography.font.family.inter};
    color: ${({ theme }) => theme.colors.tfwBlack40};
    text-align: center;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: 16px;
        line-height: 24px;
        margin-top: 8px;
    }
`
