import React, { MouseEventHandler, ReactNode, FC } from 'react'

import OpenMenuIcon from '../../assets/images/navigation.svg'
import { ButtonContainer } from '@src/components/common/navigation-icon-styles'

interface Props {
    onClick: MouseEventHandler
    customOpenMenuIcon?: ReactNode
}

const NavigationButton: FC<Props> = ({ onClick, customOpenMenuIcon }) => (
    <ButtonContainer type="button" onClick={onClick}>
        {customOpenMenuIcon || <OpenMenuIcon />}
    </ButtonContainer>
)

export default NavigationButton
