import styled, { StyledComponent, AnyStyledComponent } from 'styled-components'
import { desktopBreakpoint, tabletBreakpoint, SolidButton } from 'react-components'

import { H3, P } from '@src/components/common/styles'

import TickImage from '../../../assets/images/tick-sign.svg'

import PremiumPartnershipLogo from '../../../assets/images/premium-partnership-logo.svg'

export const SectionContent = styled.div`
    max-width: 1200px;
    margin: 8px auto 16px;
    width: 100%;
    display: ${props => props.theme.style.display.flex};
    flex-direction: column;
    justify-content: center;
    text-align: left;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: 16px auto;
        align-items: center;
    }
`

export const Container = styled.div`
    text-align: center;
    padding: 24px;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: 48px 24px;
    }
`

export const PremiumPartnershipIconContainer = styled.div`
    display: inline-block;
    width: 163px;
    height: 90px;
    text-align: center;
`

export const Icon = styled.img.attrs({ loading: 'lazy', width: '140', height: '140' })`
    width: 100%;
    height: 100%;
`

export const TitleContainer = styled.div`
    margin: 1rem auto 0;
`

export const PremiumPartnershipText = styled.div`
    color: #fff;
    padding: 8px;
    display: ${props => props.theme.style.display.flex};
`

export const Title = styled(H3).attrs({ as: 'h1' })`
    color: #fff;
    font: 700 25px/30px ${({ theme }) => theme.typography.font.family.inter};

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        font-size: 40px;
        line-height: 48px;
    }
`

export const Description = styled(P)`
    color: #999999;
    font: 400 ${({ theme }) => theme.typography.font.size.small} / 22px
        ${({ theme }) => theme.typography.font.family.inter};
    margin: 8px auto 0;

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        font-size: 16px;
        line-height: 24px;
    }
`
export const ButtonContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    width: 100%;
    text-align: center;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: auto;
        max-width: 282px;
    }
`

export const ButtonOuterContainer = styled.div``

export const ButtonContainerText = styled.div`
    margin: 12px 0;
    font: 700 ${({ theme }) => theme.typography.font.size.medium} / 24px
        ${({ theme }) => theme.typography.font.family.inter};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: 12px;
    }
`

export const TickIcon = styled(TickImage as AnyStyledComponent)`
    width: 100%;
    height: 100%;
    color: #fff;
    margin: 0 0 0 auto;
    transition: transform 0.25s ease;
`
export const PremiumPartnershipTextContainer = styled.p``

export const IconContainer = styled.div`
    width: 24px;
    height: 24px;
    margin-right: 12px;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-right: 0;
    }
`

export const ItemContainer = styled.div`
    font: 400 ${({ theme }) => theme.typography.font.size.small} / 22px
        ${({ theme }) => theme.typography.font.family.inter};
    color: #ffffff;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-left: 12px;
        font-size: 16px;
        line-height: 24px;
    }
`

export const PremiumPartnershipIcon = styled(PremiumPartnershipLogo)`
    width: 100%;
    height: 100%;
`

export const RegisterButton = styled(SolidButton)`
    padding: 0;
    width: ${({ theme }) => theme.layout.fill};
    background-color: #f1aa10;
    border-radius: 8px;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        max-width: 282px;
    }
`
