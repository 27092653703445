import React, { FC, memo, Fragment, useContext } from 'react'
import {
    Title,
    ImageLoader,
    SliderContainer,
    Container,
    TitleContentContainer,
    SliderContentContainer,
    ImageContainer,
} from './slider-styles'
import { ScreenContext } from 'react-components'
import { items } from './hero-slider-items'

import { useTranslation } from '../../../hooks/locale'

import Slider from '../slider'

const HeroSlider: FC = () => {
    const { isMobile, isTablet } = useContext(ScreenContext)
    const { t } = useTranslation()

    const renderItems = (src: string, alt: string): React.ReactElement<HTMLElement> => {
        return (
            <ImageContainer>
                <ImageLoader src={src} alt={alt} width={90} height={28} />
            </ImageContainer>
        )
    }

    const _visibleSlideRatio = isMobile ? 1.7 : isTablet ? 4 : 3.5

    return (
        <Fragment>
            <Container>
                <TitleContentContainer>
                    <Title>{t('sliderTitle')}</Title>
                </TitleContentContainer>
                <SliderContentContainer>
                    <SliderContainer>
                        <Slider
                            items={items}
                            visibleSlide={_visibleSlideRatio}
                            delay={3000}
                            renderItems={(src: string, alt: string) => renderItems(src, alt)}
                        />
                    </SliderContainer>
                </SliderContentContainer>
            </Container>
        </Fragment>
    )
}

export default memo(HeroSlider)
