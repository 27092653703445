import React, { memo, useCallback, useContext } from 'react'
import {
    ContactArea,
    EmailItemContainer,
    PhoneItemContainer,
    Container,
    Email,
    HelpText,
    HelpTextArea,
    Phone,
    Wrapper,
} from './customer-support-detail-styles'
import { AnalyticsService } from '@src/services'
import { homePageEventCategories } from '@src/services/analytics-service/categories'
import { CustomerSupportSectionTransport } from '@common/transports/home-page-transport'
import PhoneIcon from '../../../assets/images/phone-outgoing.svg'
import EmailIcon from '../../../assets/images/mail.svg'
import { ScreenContext } from 'react-components'

type Props = {
    customerSupport: CustomerSupportSectionTransport
}

const CustomerSupportDetails: React.FC<Props> = ({
    customerSupport: { phone, email, heroImageMiniFormCustomerSupportTitle },
}) => {
    const { isTablet } = useContext(ScreenContext)
    const _handleClickOnEmail = useCallback(() => {
        if (email.button.eventLabel) {
            AnalyticsService.trackClickEvent(homePageEventCategories.HERO_SECTION, email.button.eventLabel)
        }
    }, [email.button.eventLabel])

    const _handleClickOnPhone = useCallback(() => {
        if (phone.button.eventLabel) {
            AnalyticsService.trackClickEvent(homePageEventCategories.HERO_SECTION, phone.button.eventLabel)
        }
    }, [phone.button.eventLabel])

    return (
        <Wrapper>
            <Container>
                <HelpTextArea>
                    <HelpText>{heroImageMiniFormCustomerSupportTitle}</HelpText>
                </HelpTextArea>
                <ContactArea>
                    <PhoneItemContainer>
                        {isTablet && <PhoneIcon />}
                        <Phone onClick={_handleClickOnPhone} href={phone.button.url}>
                            {phone.button.text}
                        </Phone>
                    </PhoneItemContainer>
                    <EmailItemContainer>
                        {isTablet && <EmailIcon />}
                        <Email onClick={_handleClickOnEmail} href={email.button.url}>
                            {email.button.text}
                        </Email>
                    </EmailItemContainer>
                </ContactArea>
            </Container>
        </Wrapper>
    )
}

export default memo(CustomerSupportDetails)
