import {
    FormInputOptionTransport,
    Salutation,
    RegistrationRequestBodyTransport,
} from '../../../../../common/transports/ho-registration-form-transports'

class RegistrationFormModel {
    public salutation: FormInputOptionTransport<Salutation> | null
    public firstName: string
    public lastName: string
    public email: string
    public phone: string
    public password: string | null

    constructor({
        salutation,
        firstName,
        lastName,
        email,
        phone,
        password,
    }: {
        salutation: FormInputOptionTransport<Salutation> | null
        firstName: string
        lastName: string
        email: string
        phone: string
        password: string | null
    }) {
        this.salutation = salutation
        this.firstName = firstName
        this.lastName = lastName
        this.email = email
        this.phone = phone
        this.password = password
    }

    // TODO: Take host domain, to compute pre-selected country here.
    public static createDefault(): RegistrationFormModel {
        return new RegistrationFormModel({
            salutation: null,
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            password: '',
        })
    }

    public static toRequestBody(registrationFormModel: RegistrationFormModel): RegistrationRequestBodyTransport {
        return {
            salutation: (registrationFormModel.salutation as FormInputOptionTransport<Salutation>)?.value,
            firstName: registrationFormModel.firstName,
            lastName: registrationFormModel.lastName,
            email: registrationFormModel.email,
            phone: `+${registrationFormModel.phone}`,
            password: registrationFormModel.password,
        }
    }
}

export default RegistrationFormModel
