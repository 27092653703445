import styled from 'styled-components'
import { desktopBreakpoint, tabletBreakpoint, wideScreenBreakpoint } from 'react-components'

import { P } from '@src/components/common/styles'

export const StepsContainer = styled.div`
    margin: 8px 0 16px;
    display: ${props => props.theme.style.display.flex};
    flex-direction: column;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: 32px 0;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        position: relative;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin: 32px 0 ${({ theme }) => theme.spacing.large};
    }
    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        margin: 48px 0 ${({ theme }) => theme.spacing.large};
    }
`

export const Icon = styled.div`
    display: none;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        display: block;
        position: absolute;
        width: 420px;
        height: 60px;
        left: calc(50% - 210px);
        top: -16px;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        width: 556px;
        height: 80px;
        left: calc(50% - 278px);
    }
    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        width: 642px;
        height: 80px;
        left: calc(50% - 321px);
    }
`

export const ButtonContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    text-align: center;
    margin: auto;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        width: unset;
        max-width: 282px;
        margin: 0px auto 16px;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        width: unset;
        max-width: 282px;
        margin: 16px auto;
    }
`

export const ButtonTextContainer = styled(P)`
    margin: 12px 0;
    font: 700 ${({ theme }) => theme.typography.font.size.medium} / 24px
        ${({ theme }) => theme.typography.font.family.inter};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: ${({ theme }) => theme.spacing.small} 2.75rem;
        font-size: ${({ theme }) => theme.typography.font.size.medium};
    }
`
