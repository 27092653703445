import styled from 'styled-components'
import { desktopBreakpoint, tabletBreakpoint, wideScreenBreakpoint } from 'react-components'
import Image from 'next/image'

export const ContentContainer = styled.div`
    margin: auto;
    display: grid;
    grid-template-columns: repeat(1, 4fr);
    grid-gap: 16px;

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        grid-gap: 12px;
        grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        grid-gap: 22px;
        grid-template-columns: repeat(2, 1fr);
    }
`

export const Container = styled.div`
    height: 100%;
    display: ${props => props.theme.style.display.flex};
    justify-content: center;
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        justify-content: end;
    }
`

export const ImageContainer = styled.div`
    margin: auto;
    height: 123px;
    width: 165px;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        height: 132px;
        width: 176px;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        height: 165px;
        width: 220px;
    }
`

export const InnerContainer = styled.div`
    margin-top: 16px;
`

export const ImageLoader = styled(Image).attrs(() => ({
    loading: 'lazy',
}))`
    display: block;
`
