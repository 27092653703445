import React, { useEffect, useState, useRef, FC } from 'react'

interface ImageItem {
    src: string
    alt: string
}

interface SlideshowProps {
    items: ImageItem[]
    visibleSlide: number
    delay: number
    renderItems: (src: string, alt: string) => React.ReactElement<HTMLElement>
}

import { SlideshowContainer, SlideshowSlider, Slide } from './styles'

const Slider: FC<SlideshowProps> = ({ items, visibleSlide, delay, renderItems }) => {
    const [index, setIndex] = useState(0)
    const timeoutRef = useRef<NodeJS.Timeout | null>(null)
    const itemLength = items.length

    useEffect(() => {
        const resetTimeout = () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
        }

        resetTimeout()
        timeoutRef.current = setTimeout(() => {
            setIndex(prevIndex => (prevIndex >= Math.ceil(itemLength - visibleSlide) ? 0 : prevIndex + 1))
        }, delay)

        return () => {
            resetTimeout()
        }
    }, [index, items, delay, visibleSlide, itemLength])

    return (
        <SlideshowContainer>
            <SlideshowSlider length={visibleSlide} index={index}>
                {items.map((item: ImageItem, idx: number) => (
                    <Slide key={idx} length={visibleSlide}>
                        {renderItems(item.src, item.alt)}
                    </Slide>
                ))}
            </SlideshowSlider>
        </SlideshowContainer>
    )
}

export default Slider
