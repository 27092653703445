import React, { FC, memo, useCallback, useMemo, useState } from 'react'
import { Form, FormikHelpers } from 'formik'
import { FormTransport } from '../../../../../common/transports/ho-registration-form-transports'
import getRegistrationFormSchema from '../utils/get-registration-form-schema'
import RegistrationFormModel from '../models/registration-form-model'
import { FormManager } from 'react-components'
import RegistrationFormView from './registration-form-view'

interface Props {
    form: FormTransport
    onSubmit: (
        values: RegistrationFormModel,
        formikHelpers: FormikHelpers<RegistrationFormModel>,
    ) => void | Promise<any>
    formData: RegistrationFormModel
}

const RegistrationFormContainer: FC<Props> = ({ form, onSubmit, formData }) => {
    const _initialState = new RegistrationFormModel(formData)

    const _validationSchema = useMemo(() => getRegistrationFormSchema(form), [form])

    const [isLoading, setIsLoading] = useState(false)
    const [submitCount, setSubmitCount] = useState(0)

    const _handleSubmit = useCallback(
        async (values, formikHelpers) => {
            setIsLoading(true)
            try {
                await onSubmit(values, formikHelpers)
            } catch {
            } finally {
                setIsLoading(false)
            }
        },
        [onSubmit],
    )

    return (
        <FormManager
            enableReinitialize
            initialValues={_initialState}
            onSubmit={_handleSubmit}
            validationSchema={_validationSchema}
            validateOnBlur={submitCount > 0}
            validateOnChange={submitCount > 0}
            blockSubmissionOnCustomWarnings
        >
            <Form>
                <RegistrationFormView form={form} isLoading={isLoading} setSubmitCount={setSubmitCount} />
            </Form>
        </FormManager>
    )
}

export default memo(RegistrationFormContainer)
