import React, { FC, memo, useCallback } from 'react'

import { StyledFooterLink } from './footer-link-styles'
import ButtonTransport from '@common/transports/common/button-transport'
import { AnalyticsService } from '@src/services'
import { homePageEventCategories } from '@src/services/analytics-service/categories'

interface Props {
    link: ButtonTransport
}

const FooterLink: FC<Props> = ({ link }) => {
    const _handleClick = useCallback(() => {
        if (link.eventLabel) {
            AnalyticsService.trackClickEvent(homePageEventCategories.FOOTER_SECTION, link.eventLabel)
        }
    }, [link])

    return (
        <StyledFooterLink href={link.url} target={link.target || undefined} onClick={_handleClick}>
            {link.text}
        </StyledFooterLink>
    )
}

export default memo(FooterLink)
