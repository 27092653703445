import styled from 'styled-components'
import { tabletBreakpoint } from 'react-components'

import { P } from '@src/components/common/styles'

export const StyledFAQGroup = styled(P).attrs({ as: 'li' })<{ isSelected: boolean }>`
    display: inline-block;
    white-space: nowrap;
    transition: all 0.5s;
    cursor: pointer;
    font: 400 ${({ theme }) => theme.typography.font.size.small} / 22px
        ${({ theme }) => theme.typography.font.family.inter};
    color: ${({ isSelected }) => (isSelected ? '#006DDB' : '#282828')};
    box-shadow: inset 0 -2px 0 ${({ isSelected }) => (isSelected ? '#006DDB' : '#282828')};
    padding: ${({ theme }) => `${theme.spacing.xSmall}`};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        ${({ isSelected }) => (isSelected ? `box-shadow: inset 0px -2px 0px #006DDB` : `box-shadow: unset`)};
    }
`
