import React, { FC, Fragment, useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { useRouter } from 'next/router'
import {
    SolidButton,
    FormDropdownInput,
    FormPhoneInput,
    FormTextInput,
    FormEmailInput,
    ScreenContext,
} from 'react-components'
import { ThemeContext } from 'styled-components'
import { useFormikContext } from 'formik'
import { FormTransport } from '../../../../../common/transports/ho-registration-form-transports'
import { NameContainer, ButtonText, CommunicationContainer, InnerNameContainer } from './styles'
import { useRequestUserConfigContext } from '@src/hooks/request'
import { getCountryCode } from '@common/utils/request-utils'
import { userClient } from '@common/clients'
import { useErrorFocus, useFormErrorTrackingOnSubmit } from './hooks'
import type ValidateEmailTransport from '@common/transports/common/validate-email-transport'
import { AnalyticsService } from '@src/services'
import { homePageEventCategories, registrationFormEventCategories } from '@src/services/analytics-service/categories'
import { registrationFormEventLabels } from '@src/services/analytics-service/labels'
import { registrationFormEventActions } from '@src/services/analytics-service/actions'
import getRegistrationEmailEventLabels from '@src/services/analytics-service/utils/get-registration-email-event-labels'
import getRegistrationEventLabels from '@src/services/analytics-service/utils/get-registration-event-labels'

interface Props {
    form: FormTransport
    setSubmitCount: (submitCount: number) => void
    isLoading?: boolean
}

const _getCountryCode = (hostname?: string | null, language?: string | null) =>
    getCountryCode(hostname, language).toLowerCase() || 'de'

const _validateEmail = async (email: string): Promise<ValidateEmailTransport> => {
    const response = await userClient.validateEmail({ email })
    return response.data
}

const RegistrationFormView: FC<Props> = ({ form, setSubmitCount, isLoading }) => {
    const isHomeowner = useRef(false)
    const isGuest = useRef(false)
    const isAccountDeletionInProgress = useRef(false)
    const setIsHomeowner = useCallback((value: boolean) => (isHomeowner.current = value), [])
    const setIsGuest = useCallback((value: boolean) => (isGuest.current = value), [])
    const setIsAccountDeletionInProgress = useCallback(
        (value: boolean) => (isAccountDeletionInProgress.current = value),
        [],
    )

    const theme = useContext(ThemeContext)
    const { query } = useRouter()

    const requestUserConfig = useRequestUserConfigContext()
    const hostname = requestUserConfig?.domain
    const _country = useMemo(() => _getCountryCode(hostname, query?.lang as string), [hostname, query?.lang])

    const { submitCount } = useFormikContext()

    useEffect(() => {
        setSubmitCount(submitCount)
    }, [setSubmitCount, submitCount])

    useErrorFocus()
    useFormErrorTrackingOnSubmit()

    const checkIfEmailAlreadyRegistered = useCallback(
        async (
            value: string,
            setCustomError: (error?: string) => void,
            setCustomWarning: (warning?: string | undefined) => void,
        ) => {
            try {
                const { isValid, isHomeowner, isGuest, isAccountDeletionInProgress, message } = await _validateEmail(
                    value,
                )

                if (!isValid) {
                    setIsAccountDeletionInProgress(!!isAccountDeletionInProgress)
                    setIsHomeowner(!!isHomeowner)
                    setIsGuest(!!isGuest)
                    if (isAccountDeletionInProgress) {
                        setCustomError(message)
                    } else if (isHomeowner || isGuest) {
                        setCustomWarning(message)
                    } else {
                        setCustomError(message)
                    }

                    const eventLabel = getRegistrationEmailEventLabels(
                        value,
                        isHomeowner,
                        isGuest,
                        isAccountDeletionInProgress,
                    )

                    if (eventLabel) {
                        AnalyticsService.trackEvent(
                            registrationFormEventActions.ON_BLUR,
                            registrationFormEventCategories.FIELD_ERROR,
                            eventLabel,
                        )
                    }
                }
            } catch {}
        },
        [setIsAccountDeletionInProgress, setIsGuest, setIsHomeowner],
    )

    const doCustomEmailValidation = useCallback(
        ({ value, remove, setCustomError, setCustomWarning }) => {
            if (remove) {
                setIsHomeowner(false)
                setIsGuest(false)
                setIsAccountDeletionInProgress(false)
            } else {
                checkIfEmailAlreadyRegistered(value, setCustomError, setCustomWarning)
            }
        },
        [checkIfEmailAlreadyRegistered, setIsAccountDeletionInProgress, setIsGuest, setIsHomeowner],
    )

    const trackOnClick = useCallback((label: string) => {
        switch (label) {
            case 'salutation':
                AnalyticsService.trackClickEvent(
                    homePageEventCategories.CANARY_HO,
                    registrationFormEventLabels.field.SALUTATION,
                )
                break
            case 'firstName':
                AnalyticsService.trackClickEvent(
                    homePageEventCategories.CANARY_HO,
                    registrationFormEventLabels.field.FIRST_NAME,
                )
                break
            case 'lastName':
                AnalyticsService.trackClickEvent(
                    homePageEventCategories.CANARY_HO,
                    registrationFormEventLabels.field.LAST_NAME,
                )
                break
            case 'email':
                AnalyticsService.trackClickEvent(
                    homePageEventCategories.CANARY_HO,
                    registrationFormEventLabels.field.EMAIL,
                )
                break
        }
    }, [])

    const trackValidationOnBlur = useCallback(({ name, value, error }) => {
        if (value) {
            AnalyticsService.trackEvent(
                registrationFormEventActions.ON_DATA_FILLED,
                registrationFormEventCategories.REGISTRATION_FORM,
                name.replaceAll('.', '_'),
            )
        }
        const eventLabel = getRegistrationEventLabels(name, value)
        if (eventLabel && error) {
            AnalyticsService.trackEvent(
                registrationFormEventActions.ON_BLUR,
                registrationFormEventCategories.FIELD_ERROR,
                eventLabel,
            )
        }
    }, [])

    const trackValidationOnSubmit = useCallback(({ name, value }) => {
        const eventLabel = getRegistrationEventLabels(name, value)
        if (eventLabel) {
            AnalyticsService.trackEvent(
                registrationFormEventActions.ON_SUBMIT,
                registrationFormEventCategories.FIELD_ERROR,
                eventLabel,
            )
        }
    }, [])

    const { isMobile } = useContext(ScreenContext)

    return (
        <Fragment>
            <NameContainer>
                <FormDropdownInput
                    name="salutation"
                    {...form.salutation}
                    fontFamily="Inter"
                    fontSize={isMobile ? '14px' : '16px'}
                    lineHeight="24px"
                    iconMargin="10.5px"
                    fontColor="#6f6f6f"
                    onInputChange={() => {
                        trackOnClick('salutation')
                    }}
                    isDisabled={false}
                />
                <InnerNameContainer>
                    <FormTextInput
                        name="firstName"
                        {...form.firstName}
                        fontFamily="Inter"
                        fontSize={isMobile ? '14px' : '16px'}
                        lineHeight="22px"
                        borderRadius="8px"
                        fontColor="#6f6f6f"
                        disabled={false}
                        onClick={() => {
                            trackOnClick('firstName')
                        }}
                        trackValidationOnBlur={trackValidationOnBlur}
                        trackValidationOnSubmit={trackValidationOnSubmit}
                    />
                    <FormTextInput
                        name="lastName"
                        {...form.lastName}
                        fontFamily="Inter"
                        fontSize={isMobile ? '14px' : '16px'}
                        lineHeight="22px"
                        borderRadius="8px"
                        fontColor="#6f6f6f"
                        disabled={false}
                        onClick={() => {
                            trackOnClick('lastName')
                        }}
                        trackValidationOnBlur={trackValidationOnBlur}
                        trackValidationOnSubmit={trackValidationOnSubmit}
                    />
                </InnerNameContainer>
            </NameContainer>
            <CommunicationContainer>
                <FormEmailInput
                    name="email"
                    {...form.email}
                    fontFamily="Inter"
                    fontSize={isMobile ? '14px' : '16px'}
                    lineHeight="22px"
                    borderRadius="8px"
                    fontColor="#6f6f6f"
                    disabled={false}
                    onClick={() => {
                        trackOnClick('email')
                    }}
                    doCustomValidation={doCustomEmailValidation}
                    trackValidationOnBlur={trackValidationOnBlur}
                    trackValidationOnSubmit={trackValidationOnSubmit}
                />
                <FormPhoneInput
                    name="phone"
                    {...form.phone}
                    fontFamily="Inter"
                    fontSize={isMobile ? '14px' : '16px'}
                    lineHeight="22px"
                    borderRadius="8px"
                    fontColor="#6f6f6f"
                    countryCode={_country}
                    trackValidationOnBlur={trackValidationOnBlur}
                    trackValidationOnSubmit={trackValidationOnSubmit}
                />
            </CommunicationContainer>
            <SolidButton
                type="submit"
                as="button"
                padding={`12px ${theme.spacing.large}`}
                borderRadius="8px"
                width="100%"
                isLoading={isLoading}
                backgroundColor="#F2B021"
            >
                <ButtonText>{form.submitButton.text}</ButtonText>
            </SolidButton>
        </Fragment>
    )
}

export default RegistrationFormView
