import styled, { createGlobalStyle } from 'styled-components'
import { tabletBreakpoint } from 'react-components'

export const CommonStyles = createGlobalStyle`
    html {
        scroll-behavior: smooth;
    }

    p, h1, h2, h3, h4, h5, h6, ul, li {
        margin: 0;
        padding: 0;
    }
`

export const SectionContainer = styled.section`
    padding-top: ${({ theme }) => theme.spacing.xxxLarge};
    margin-top: -${({ theme }) => theme.spacing.xxxLarge};

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding-top: 5rem;
        margin-top: -5rem;
    }
`
