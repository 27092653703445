import React, { FC, memo, useMemo, Fragment } from 'react'
import { theme, CircularGhostButton } from 'react-components'

import { useCurrentSlideIndex } from '@src/hooks/carousel'
import { StyledDot } from './styles'
import { AnalyticsService } from '@src/services'
import { homePageEventCategories } from '@src/services/analytics-service/categories'
import { homePageEventLabels } from '@src/services/analytics-service/labels'

interface Props {
    numberOfDots: number
}

const _handleDotClick = () => {
    AnalyticsService.trackClickEvent(
        homePageEventCategories.TESTIMONIALS_SECTION,
        homePageEventLabels.testimonialsSection.CHANGE_CAROUSEL_SLIDE,
    )
}

const renderDots = (_numberOfDots: number, _selectedDotIndex: number) => {
    const arr = []
    for (let i = 0; i < _numberOfDots; i++) {
        arr.push(
            <StyledDot slide={i * 2} key={i * 2} onClick={_handleDotClick}>
                <CircularGhostButton
                    radius={theme.typography.border.radius.xSmall}
                    fillColor={_selectedDotIndex === i ? theme.colors.tfwBlack45 : theme.colors.white}
                    borderColor={theme.colors.tfwBlack45}
                    hoverEffect={false}
                />
            </StyledDot>,
        )
    }
    return arr
}

const CarouselDots: FC<Props> = ({ numberOfDots }) => {
    const _selectedDotIndex = Math.ceil(useCurrentSlideIndex() / 2)
    const _dots = useMemo(() => renderDots(numberOfDots, _selectedDotIndex), [_selectedDotIndex, numberOfDots])
    return <Fragment>{_dots}</Fragment>
}

export default memo(CarouselDots)
