import styled, { AnyStyledComponent } from 'styled-components'
import { tabletBreakpoint } from 'react-components'

import { P, H5 } from '@src/components/common/styles'

import CoupleLogo from '../../../assets/images/couple.svg'
import ShieldLogo from '../../../assets/images/shield.svg'
import MoneyLogo from '../../../assets/images/money.svg'
import HelpdeskLogo from '../../../assets/images/helpdesk.svg'
import DashboardLogo from '../../../assets/images/dashboard.svg'
import EffectsLogo from '../../../assets/images/effects.svg'

export const Container = styled.div`
    margin-top: 16px;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        display: ${props => props.theme.style.display.flex};
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 0;
    }
`

export const BenefitContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    margin: auto;
    align-items: center;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        flex-direction: column;
        padding: 0;
        align-items: center;
        width: calc(33.33% - 24px);
        margin: 24px 0 16px;
    }
`

export const IconContainer = styled.div`
    height: 50px;
    width: 50px;
    margin-right: 1rem;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        width: 88px;
        height: 88px;
        margin: auto;
    }
`

export const Icon = styled.img.attrs({ loading: 'lazy', width: '140', height: '140' })`
    width: 100%;
    height: 100%;
`

export const Title = styled(H5).attrs({ as: 'h2' })`
    margin-bottom: ${props => props.theme.spacing.xxSmall};
    font: 700 ${({ theme }) => theme.typography.font.size.large} / 26px
        ${({ theme }) => theme.typography.font.family.inter};
    color: ${({ theme }) => theme.colors.tfwBlack99};

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: 16px auto 4px;
        text-align: center;
    }
`

export const Description = styled(P)`
    font: 400 ${({ theme }) => theme.typography.font.size.small} / 22px
        ${({ theme }) => theme.typography.font.family.inter};
    color: ${({ theme }) => theme.colors.tfwBlack40};

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        text-align: center;
    }
`

export const BenefitContentContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex-direction: column;
    margin: 16px 0 8px;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: 0;
    }
`

export const CoupleIcon = styled(CoupleLogo as AnyStyledComponent)`
    width: 100%;
    height: 100%;
`

export const ShieldIcon = styled(ShieldLogo as AnyStyledComponent)`
    width: 100%;
    height: 100%;
`

export const MoneyIcon = styled(MoneyLogo as AnyStyledComponent)`
    width: 100%;
    height: 100%;
`

export const HelpdeskIcon = styled(HelpdeskLogo as AnyStyledComponent)`
    width: 100%;
    height: 100%;
`

export const DashboardIcon = styled(DashboardLogo as AnyStyledComponent)`
    width: 100%;
    height: 100%;
`

export const EffectsIcon = styled(EffectsLogo as AnyStyledComponent)`
    width: 100%;
    height: 100%;
`
export const InnerContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        flex-direction: column;
    }
`

export const ModalContainer = styled.a`
    font-family: ${props => props.theme.typography.font.family.inter};
    line-height: 1.5rem;
    font-feature-settings: 'liga' off;
    cursor: pointer;
    width: 4.4rem;
    white-space: nowrap;
    text-decoration: underline;
`
