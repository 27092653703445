import AirbnbLogo from '../../../assets/picture/airbnb-logo.png'
import BookingDotComLogo from '../../../assets/picture/booking-logo.png'
import HoliduLogo from '../../../assets/picture/holidu-logo.png'
import HomeToGoLogo from '../../../assets/picture/home-to-go-logo.png'
import BelvillaLogo from '../../../assets/picture/belvilla-logo.png'
import DancenterLogo from '../../../assets/picture/dancenter-logo.png'

interface ImageItem {
    src: string
    alt: string
}

export const items: ImageItem[] = [
    { src: AirbnbLogo.src, alt: 'Airbnb Logo' },
    { src: BookingDotComLogo.src, alt: 'Booking.com Logo' },
    { src: HoliduLogo.src, alt: 'Holidu Logo' },
    { src: HomeToGoLogo.src, alt: 'HomeToGo Logo' },
    { src: BelvillaLogo.src, alt: 'Belvilla Logo' },
    { src: DancenterLogo.src, alt: 'Dancenter Logo' },
]
