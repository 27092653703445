import styled from 'styled-components'
import Image from 'next/image'
import { desktopBreakpoint, tabletBreakpoint, wideScreenBreakpoint } from 'react-components'

export const ImageLoader = styled(Image).attrs({
    loading: 'lazy',
})`
    display: block;
    width: 100%;
    height: 100%;
`

export const SliderContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex: 1;
    height: 100%;
    width: 100%;
`

export const Title = styled.div`
    border-right: 1px dotted #6b44a5;
    font: 400 11px/16px ${({ theme }) => theme.typography.font.family.inter};
    color: #8055c3;
    text-transform: uppercase;
    padding-right: 8px;
    text-align: left;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-top: 8px;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin-top: 0px;
    }
`

export const Container = styled.div`
    display: ${props => props.theme.style.display.flex};
    background-color: #f9f5ff;
    border-radius: 16px;
    padding: 24px 0 24px 24px;
    align-items: center;
    margin: auto;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        width: 90%;
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        width: 100%;
        margin-left: 0;
    }
`

export const TitleContentContainer = styled.div`
    max-width: 96px;
`

export const SliderContentContainer = styled.div`
    padding-left: 16px;
    display: ${props => props.theme.style.display.flex};
    flex: 1;
    height: 100%;
    width: 100%;
`

export const ImageContainer = styled.div`
    height: 100%;
    width: 100%;
    margin: auto;
`
