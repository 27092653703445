import { getIn, useFormikContext } from 'formik'
import { useEffect, useRef } from 'react'
import { useFormContext, SerializableObject } from 'react-components'
import { AnalyticsService } from '@src/services'
import { registrationFormEventCategories } from '@src/services/analytics-service/categories'
import { registrationFormEventActions } from '@src/services/analytics-service/actions'
import { registrationFormEventLabels } from '@src/services/analytics-service/labels'
import { isDeepEmpty } from '@src/utils/object-utils'

const _orderedFormFieldKeys = ['salutation', 'firstName', 'lastName', 'email', 'phone']

const _getFirstErrorName = (...objects: SerializableObject[]) => {
    for (const currentObject of objects) {
        for (const formFieldKey of _orderedFormFieldKeys) {
            const value = getIn(currentObject, formFieldKey)
            if (value !== undefined && value !== null) {
                return formFieldKey
            }
        }
    }
}

export const useErrorFocus = () => {
    const { isSubmitting, isValidating, errors } = useFormikContext()
    const { errors: customErrors, warnings: customWarnings } = useFormContext()

    // Scroll/focus to/on the top-most error.
    useEffect(() => {
        if (isSubmitting && !isValidating) {
            // Delay execution so that all errors are painted into the DOM before scrolling to them.
            setTimeout(() => {
                const firstErrorElementName = _getFirstErrorName(errors, customErrors, customWarnings)
                if (firstErrorElementName) {
                    const errorElement = document.querySelector(`[data-name="${firstErrorElementName}"]`)
                    if (errorElement) {
                        errorElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
                    }
                }
            }, 0)
        }
    }, [isSubmitting, isValidating, errors, customErrors, customWarnings])
}

export const useFormErrorTrackingOnSubmit = () => {
    const isMounted = useRef(false)
    const { isSubmitting, values } = useFormikContext()

    useEffect(() => {
        if (!isSubmitting && isMounted.current) {
            const isFormEmpty = isDeepEmpty(values)
            if (isFormEmpty) {
                AnalyticsService.trackEvent(
                    registrationFormEventActions.ON_SUBMIT,
                    registrationFormEventCategories.FIELD_ERROR,
                    registrationFormEventLabels.fieldError.ALL_FIELDS_EMPTY,
                )
            }
        }
        isMounted.current = true
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitting])
}
