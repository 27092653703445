import styled from 'styled-components'
import { tabletBreakpoint } from 'react-components'

import UpArrow from '../../../assets/images/up-arrow.svg'
import DownArrow from '../../../assets/images/down-arrow.svg'
import { CustomMarkdown, H6 } from '@src/components/common/styles'
import { SectionContainer } from '@src/components/home-page-v3/styles'

export const OffsetContainer = styled(SectionContainer).attrs({ as: 'div' })``

export const Container = styled.div`
    padding: ${({ theme }) => `${theme.spacing.medium} 0 ${theme.spacing.large}`};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: ${({ theme }) => `${theme.spacing.large} 0`};
    }
`

export const TitleContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`

export const Title = styled(H6).attrs({ as: 'h3' })`
    letter-spacing: -0.35px;
    font: 400 ${({ theme }) => theme.typography.font.size.medium} / 24px
        ${({ theme }) => theme.typography.font.family.inter};
    color: ${({ theme }) => theme.colors.tfwBlack99};
`

const Icon = styled.img`
    width: 1rem;
    height: 0.5rem;
    color: ${({ theme }) => theme.colors.tfwBlack87};
    transition: transform 0.25s ease;
`

export const UpArrowIcon = styled(Icon).attrs({ as: UpArrow })``
export const DownArrowIcon = styled(Icon).attrs({ as: DownArrow })``

export const Description = styled(CustomMarkdown)`
    font: 400 ${({ theme }) => theme.typography.font.size.small} / 22px
        ${({ theme }) => theme.typography.font.family.inter};
    color: #6f6f6f;
    padding-top: ${({ theme }) => theme.spacing.medium};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding-top: ${({ theme }) => theme.spacing.small};
    }
`
