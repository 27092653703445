import styled, { AnyStyledComponent } from 'styled-components'
import PhoneIcon from '../../../assets/images/phone-icon.svg'
import { mobileBreakpoint, SolidButton } from 'react-components'

export const Container = styled.div<{ visibility: boolean }>`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    padding: 8px 16px;
    box-shadow: inset 0 -0.0625rem 0 #0000001e;
    z-index: 1000;
    display: ${props => (props.visibility ? 'flex' : 'none')};
    border-top: 1px solid #d3d3d3;
`

export const InnerContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    width: 100%;
    justify-content: center;

    & > div:first-child {
        margin-right: 10px;
    }
`

export const ButtonContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    width: 100%;
    text-align: center;
    padding: 2px;
    max-width: 160px;
`

export const ButtonContainerText = styled.div`
    margin: 8px 0;
    font: 600 ${({ theme }) => theme.typography.font.size.small} / 24px
        ${({ theme }) => theme.typography.font.family.inter};
    color: ${({ theme }) => theme.colors.tfwBlack99};
    @media only screen and (max-width: ${mobileBreakpoint.max}px) {
        font-size: 0.8rem;
    }
`

export const CallUsInnerContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    margin: auto;
`

export const CallButtonContainer = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.tfwBlack99};
    border-radius: 0.375rem;
    display: ${props => props.theme.style.display.flex};
`

export const IconContainer = styled.div`
    width: 16px;
    height: 16px;
    margin: 11px 8px 11px 0;
    line-height: 24px;
`

export const PhoneImage = styled(PhoneIcon as AnyStyledComponent)`
    width: 100%;
    height: 100%;
`

export const CallUsButton = styled(SolidButton)`
    padding: 0;
    width: ${({ theme }) => theme.layout.fill};
    background-color: #fff;
    border-radius: 1px;
    color: ${({ theme }) => theme.colors.tfwBlack99};
`

export const RegisterButton = styled(SolidButton)`
    padding: 0;
    width: ${({ theme }) => theme.layout.fill};
    background-color: #f1aa10;
`
