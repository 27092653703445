import React, { FC, memo } from 'react'
import { Footer } from 'react-components'

import FooterLink from './footer-link'
import { Container, ContentContainer, Image, ImageContainer, LinksContainer, InnerContainer } from './styles'
import FooterSectionTransport from '@common/transports/layout/footer-section-transport'

interface Props {
    footerSection: FooterSectionTransport
}

const FooterSection: FC<Props> = ({ footerSection: { id, items, logo } }) => {
    const _image = (
        <ImageContainer>
            <Image src={logo.image?.url} alt={logo.image?.alt} />
        </ImageContainer>
    )

    const _links = (
        <LinksContainer>
            {items.map(link => (
                <FooterLink key={link.url} link={link} />
            ))}
        </LinksContainer>
    )

    return (
        <Footer id={id}>
            <ContentContainer>
                <InnerContainer>
                    {_image}
                    <Container>{_links}</Container>
                </InnerContainer>
            </ContentContainer>
        </Footer>
    )
}

export default memo(FooterSection)
