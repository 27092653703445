import styled from 'styled-components'
import { tabletBreakpoint, desktopBreakpoint } from 'react-components'

export const Wrapper = styled.div`
    padding: 0 ${({ theme }) => theme.spacing.medium};
    margin: -1.625rem 0 ${({ theme }) => theme.spacing.large};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: 0 ${({ theme }) => theme.spacing.large};
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        padding: 0;
        margin: 0;
    }
`

export const Container = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    padding: 1.25rem ${({ theme }) => theme.spacing.medium};
    background: #ffffff;
    border: ${({ theme }) => theme.typography.border.width.xxxThin}
        ${({ theme }) => theme.typography.border.style.solid} #00000019;
    border-radius: ${({ theme }) => theme.typography.border.radius.small};

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        background: ${({ theme }) => theme.colors.tfwWhite64};
        padding: 0.625rem ${({ theme }) => theme.spacing.xLarge};
        border: none;
        border-radius: ${({ theme }) => theme.typography.border.radius.small};
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin: -${({ theme }) => theme.spacing.xSmall} 0 0;
    }
    @media only screen and (min-width: ${tabletBreakpoint.min}px) and (max-width: ${tabletBreakpoint.max}px) {
        padding: 1.125rem 2.25rem;
    }
`

export const HelpTextArea = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    align-items: ${({ theme }) => theme.style.alignItems.center};
    border-right: ${({ theme }) => theme.typography.border.width.xxxThin}
        ${({ theme }) => theme.typography.border.style.solid} #00000019;
`

export const HelpText = styled.p`
    font-family: ${({ theme }) => theme.typography.font.family.lato};
    font-style: normal;
    font-size: ${({ theme }) => theme.typography.font.size.small};
    font-weight: ${({ theme }) => theme.typography.font.lato.weight.bold};
    line-height: 1.5rem;
    color: ${({ theme }) => theme.colors.tfwBlack87};
    padding: 0 ${({ theme }) => theme.spacing.small} 0 0;

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        padding: 0 0.625rem 0 0;
    }
    @media only screen and (min-width: ${tabletBreakpoint.min}px) and (max-width: ${tabletBreakpoint.max}px) {
        font-size: ${({ theme }) => theme.typography.font.size.medium};
    }
`

export const ContactArea = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) and (max-width: ${tabletBreakpoint.max}px) {
        flex-direction: ${({ theme }) => theme.style.flexDirection.row};
        margin-left: 1.625rem;
    }
`
const _contactItemContainer = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.row};
    align-items: ${props => props.theme.style.alignItems.center};
`

export const PhoneItemContainer = styled(_contactItemContainer)``

export const EmailItemContainer = styled(_contactItemContainer)`
    margin-top: 0.5rem;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-top: 0;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin-top: 0.25rem;
    }
`

const _text = styled.a`
    font-family: ${({ theme }) => theme.typography.font.family.lato};
    font-style: normal;
    font-weight: ${({ theme }) => theme.typography.font.lato.weight.normal};
    font-size: 0.8125rem;
    line-height: 1rem;
    letter-spacing: 0.0125rem;
    color: ${({ theme }) => theme.colors.tfwBlack87};
    text-decoration: none;
    padding: 0 0 0 0.875rem;
`

export const Phone = styled(_text)`
    margin: 0 2.125rem 0 0;
`
export const Email = styled(_text)``
